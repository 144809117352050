import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import BASE_API_URL from '../../../utils/constants'
import { useAuth } from '../../../utils/AuthContext'

function ViewMembers({to, title, text}) {
    const auth = useAuth()

    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('danger')
    const [memberResults, setMemberResults] = useState([])
    const [members, setMembers] = useState([])

    const columns = [
		{
			name: 'First Name',
			selector: row => row.firstname,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: row => row.lastname,
			sortable: true,
		},
		{
			name: 'Phone',
			selector: row => row.phone,
			sortable: false,
			grow: 2,
		},
		{
			name: 'Gender',
			selector: row => row.gender,
			sortable: true,
		},
		{
			name: 'YOS',
			selector: row => row.yos,
			sortable: true,
		},
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
		{
			name: 'Action',
			cell: row => <Link
				to={`${to}/${row.id}`}
				state={{ member: row }}
				className='btn btn-sm btn-primary'>
				{text}
                <i className='bi bi-three-dots-vertical ms-2'></i>
			</Link>,
			sortable: false,
		}
	]
    
    const handleSearch = (value) => {
        if (value.length < 1) {
            setMembers(memberResults)
            return
        }

        const searchResults = members.filter(member => {
            return member.firstname.toLowerCase().includes(value.toLowerCase()) || 
            member.lastname.toLowerCase().includes(value.toLowerCase())
        })

        setMembers(searchResults)
    }

    const fetchMembers = async () =>{
        try{
            const resp = await fetch(`${BASE_API_URL}/members`,{
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await resp.json()
            console.log(data);

            if(!resp.ok){
                setMessage(data.message)
                setVariant("danger")
            }

            setMembers(data.members)
            setMemberResults(data.members)
        }
        catch(error){
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
    }

    useEffect(()=>{
        fetchMembers()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>{title}</p>
            <div className="body shadow rounded p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1"
                    placeholder="Search members..."
                    onChange={e => handleSearch(e.target.value)}
                />
                <button className="btn btn-sm btn-primary px-3">
                    Search
                </button>
            </div>
            {
                message && 
                <div className={`alert alert-${variant}`} role="alert">
                    {message}
                </div>
            }
            <div>
                <DataTable
                    columns={columns}
                    data={members}
                    highlightOnHover
                    pagination
                    paginationPerPage={20}
                    noDataComponent={message}
                />
            </div>
        </div>
    )
}

export default ViewMembers

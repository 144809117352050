import React from 'react'
import './SideBar.css'
import { useNavigate } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/images/pcm-small.png'
import { useAuth } from '../../../../utils/AuthContext'
import ChairpersonsOffice from '../../ChairpersonsOffice'
import SpiritualsOffice from '../../SpiritualsOffice'

function SideBar() {
	const auth = useAuth()
	const user = auth.user
	const navigate = useNavigate()

	const logout = () => {
		auth.logout()
		navigate('/login')
	}

	return (
		<>
			<div className="l-navbar" id="nav-bar">
				<nav className="nav">
					<div>
						<Link to="/" className="nav_logo">
							<img className='img nav_logo-img' src={logo} alt="logo" />
							<span className="nav_logo-name">TUCASA-CIVE IMS</span>
						</Link>
						<div className="nav_list">
							<NavLink to="index" className="nav_link">
								<i className={`bi bi-speedometer2 nav_icon`}></i>
								<span className="nav_name">Dashboard</span>
							</NavLink>
						</div>
						<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-members`} aria-expanded="false" aria-controls="collapseExample">
							<NavLink to="members" className='nav_link' style={{ zIndex: -1 }}>
								<i className={`bi bi-people-fill nav_icon`}></i>
								<span className="nav_name">
									Members
								</span>
							</NavLink>
							<div className="collapse nav_sub" id={`collapse-members`}>
								<NavLink to="members/view" className="nav_sub_link">
									<span className='nav_name'>
										View Members
									</span>
								</NavLink>
								<ChairpersonsOffice>
									<NavLink to="members/new" className="nav_sub_link">
										<span className='nav_name'>
											Register New
										</span>
									</NavLink>
								</ChairpersonsOffice>
							</div>
						</div>
						<ChairpersonsOffice>
							<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-contribs`} aria-expanded="false" aria-controls="collapseExample">
								<NavLink to="contributions" className='nav_link' style={{ zIndex: -1 }}>
									<i className={`bi bi-cash-coin nav_icon`}></i>
									<span className="nav_name">
										Contributions
									</span>
								</NavLink>
								<div className="collapse nav_sub" id={`collapse-contribs`}>
									<NavLink to="contributions/current" className="nav_sub_link">
										<span className='nav_name'>
											Current Contributions
										</span>
									</NavLink>
									<NavLink to="contributions/record" className="nav_sub_link">
										<span className='nav_name'>
											Record Contribution
										</span>
									</NavLink>
									<NavLink to="contributions/analysis" className="nav_sub_link">
										<span className='nav_name'>
											Contributions Analysis
										</span>
									</NavLink>
								</div>
							</div>
						</ChairpersonsOffice>
						<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-spiritual`} aria-expanded="false" aria-controls="collapseExample">
							<NavLink to="spiritual" className='nav_link' style={{ zIndex: -1 }}>
								<i className={`bi bi-book-half nav_icon`}></i>
								<span className="nav_name">
									Spiritual Records
								</span>
							</NavLink>
							<div className="collapse nav_sub" id={`collapse-spiritual`}>
								<NavLink to="spiritual/view" className="nav_sub_link">
									<span className='nav_name'>
										View Records
									</span>
								</NavLink>
								<SpiritualsOffice>
									<NavLink to="spiritual/new" className="nav_sub_link">
										<span className='nav_name'>
											Daily Records
										</span>
									</NavLink>
									<NavLink to="spiritual/weekly" className="nav_sub_link">
										<span className='nav_name'>
											Weekly Records
										</span>
									</NavLink>
								</SpiritualsOffice>
							</div>
						</div>
						<div className="nav_list">
							<NavLink to="announcements" className="nav_link">
								<i className={`bi bi-megaphone-fill nav_icon`}></i>
								<span className="nav_name">Announcements</span>
							</NavLink>
						</div>
						<div className="nav_list">
							<NavLink to="archives" className="nav_link">
								<i className={`bi bi-file-earmark-zip-fill nav_icon`}></i>
								<span className="nav_name">Archives</span>
							</NavLink>
						</div>
						<div className='nav_link_with_sub' type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-leader`} aria-expanded="false" aria-controls="collapseExample">
							<NavLink to="leadership" className='nav_link' style={{ zIndex: -1 }}>
								<i className={`bi bi-person-square nav_icon`}></i>
								<span className="nav_name">
									Leadership
								</span>
							</NavLink>
							<div className="collapse nav_sub" id={`collapse-leader`}>
								<NavLink to="leadership/current" className="nav_sub_link">
									<span className='nav_name'>
										Current Leaders
									</span>
								</NavLink>
								<ChairpersonsOffice>
									<NavLink to="leadership/transition" className="nav_sub_link">
										<span className='nav_name'>
											Leadership Transition
										</span>
									</NavLink>
								</ChairpersonsOffice>
								<NavLink to="leadership/history" className="nav_sub_link">
									<span className='nav_name'>
										Leadership History
									</span>
								</NavLink>
							</div>
						</div>
					</div>
					<span className="nav_link cursor" onClick={logout}>
						<i className='bi bi-box-arrow-left nav_icon'></i>
						<span className="nav_name">Logout</span>
					</span>
				</nav>
			</div>
		</>
	)
}

export default SideBar

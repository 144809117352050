import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'

function LeadershipTransition() {
    const auth = useAuth()

    const [message, setMessage] = useState("Once transition is done, current leaders wont be able to login as leaders.")
    const [variant, setVariant] = useState("info")
    const [isLoading, setIsLoading] = useState(false)

    const [members, setMembers] = useState([])
    const [chairperson, setChairperson] = useState("")
    const [viceChairperson, setViceChairperson] = useState("")
    const [secretary, setSecretary] = useState("")
    const [treasurer, setTreasurer] = useState("")
    const [spiritual, setSpiritual] = useState("")
    const [communication, setCommunication] = useState("")
    const [possibility, setPossibility] = useState("")
    const [coordinator, setCoordinator] = useState("")
    const [freedom, setFreedom] = useState("")
    const [project, setProject] = useState("")
    const [medical, setMedical] = useState("")
    const [education, setEducation] = useState("")

    const fetchActiveMembers = async () => {
        try {
            const resp = await fetch(`${BASE_API_URL}/members/active`, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await resp.json()
            console.log(data);

            if (!resp.ok) {
                setMessage(data.message)
                setVariant("danger")
            }

            setMembers(data.members)
        }
        catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const resp = await fetch(`${BASE_API_URL}/leadership/transition`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    chairperson,
                    viceChairperson,
                    secretary,
                    treasurer,
                    spiritual,
                    communication,
                    possibility,
                    coordinator,
                    freedom,
                    project,
                    medical,
                    education
                })
            })
            const data = await resp.json()
            
            if (!resp.ok) {
                setMessage(data.message)
                setVariant("danger")
            }

            setMessage(data.message)
            setVariant("success")
        }
        catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchActiveMembers()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>
                Leadership Transition
            </p>
            <div className="body shadow rounded p-2 mb-3">
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <div className='mb-3'>
                    <p>Select New Leaders</p>
                    <form onSubmit={handleSubmit}>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='chairperson'>Chairperson</label>
                                <select
                                    className='form-select'
                                    id='chairperson'
                                    onChange={(e) => setChairperson(e.target.value)}
                                >
                                    <option value="">Select chairperson</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='form-group mb-3'>
                                <label htmlFor='vice'>Vice Chairperson</label>
                                <select
                                    className='form-select'
                                    id='vice'
                                    onChange={(e) => setViceChairperson(e.target.value)}
                                >
                                    <option value="">Select vice chairperson</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='secretary'>Secretary</label>
                                <select
                                    className='form-select'
                                    id='secretary'
                                    onChange={(e) => setSecretary(e.target.value)}
                                >
                                    <option value="">Select secretary</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='treasurer'>Treasurer</label>
                                <select
                                    className='form-select'
                                    id='treasurer'
                                    onChange={(e) => setTreasurer(e.target.value)}
                                >
                                    <option value="">Select treasurer</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='spiritual'>Director of Spiritual Ministry</label>
                                <select
                                    className='form-select'
                                    id='spiritual'
                                    onChange={(e) => setSpiritual(e.target.value)}
                                >
                                    <option value="">Select director of spiritual ministry</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='possibility'>Director of Possibility Ministry</label>
                                <select
                                    className='form-select'
                                    id='possibility'
                                    onChange={(e) => setPossibility(e.target.value)}
                                >
                                    <option value="">Select director of possibility ministry</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='coordinator'>Director of Program Coordination</label>
                                <select
                                    className='form-select'
                                    id='coordinator'
                                    onChange={(e) => setCoordinator(e.target.value)}
                                >
                                    <option value="">Select director of program coordination</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='freedom'>Director of Religious Freedom</label>
                                <select
                                    className='form-select'
                                    id='freedom'
                                    onChange={(e) => setFreedom(e.target.value)}
                                >
                                    <option value="">Select director of religious freedom</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='project'>Director of Project Management</label>
                                <select
                                    className='form-select'
                                    id='project'
                                    onChange={(e) => setProject(e.target.value)}
                                >
                                    <option value="">Select director of project management</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='medical'>Director of Medical Missionary</label>
                                <select
                                    className='form-select'
                                    id='medical'
                                >
                                    <option value="">Select director of medical missionary</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='communication'>Director of Communication</label>
                                <select
                                    className='form-select'
                                    id='communication'
                                    onChange={(e) => setCommunication(e.target.value)}
                                >
                                    <option value="">Select director of communication</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="rounded border mb-3 p-2">
                            <div className='form-group mb-3'>
                                <label htmlFor='education'>Director of Education</label>
                                <select
                                    className='form-select'
                                    id='education'
                                    onChange={(e) => setEducation(e.target.value)}
                                >
                                    <option value="">Select director of education</option>
                                    {
                                        members.map(member => (
                                            <option key={member.id} value={member.id}>
                                                {member.firstname} {member.middleName} {member.lastname}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="mb-3">
                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }
                        </div>
                        <div className="mb-3 text-end">
                            <button type='submit' className='btn btn-primary px-3'>
                                {isLoading ? "Saving..." : "Save"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LeadershipTransition

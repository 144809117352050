import React, { useEffect, useState } from "react";
import "./News.css"
import { Data } from "../data";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useParams, Link, Route } from "react-router-dom";
import BASE_API_URL from "../../utils/constants";


const News = () => {
    const { newsId } = useParams();

    const [announcements, setAnnouncements] = useState([])

    const fetchAnnouncements = async () => {
        const resp = await fetch(`${BASE_API_URL}/announcements/public`)
        const data = await resp.json()
        setAnnouncements(data.announcements)
    }

    useEffect(() => {
        fetchAnnouncements()
    }, [])

    return (
        <div className="News">
            <div className="latest">
                LATEST ANNOUNCEMENT
            </div>

            <div className="news">
                {
                    announcements.map((update) => {
                        return (
                            <div className="article" key={update.id}>
                                <div className="newSection">
                                    <div className="NewsFlex2">
                                        <div className="Image">
                                            <Link to={`/news/${update.id}`} className="more">
                                                <img src={`${BASE_API_URL}${update.image}`} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="NewsFlex1">
                                        <div className="tittle">
                                            <Link to={`/news/${update.id}`} className="more">
                                                <div className="words">
                                                    {update.title}
                                                </div>
                                                <div className="date">
                                                    <FontAwesomeIcon icon={faClock} size="lg" color="#00315B" />
                                                    {update.created_at}
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default News

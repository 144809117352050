import React, { useEffect, useState } from "react";
import "./Home.css"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter,faYoutube,faXTwitter} from '@fortawesome/free-brands-svg-icons';
import { faContactBook, faPhone,faMapMarker, faEnvelope, faArrowLeft, faArrowRight, faBook, faSync} from '@fortawesome/free-solid-svg-icons';
import Music from "../../components/Music/Music";
import myYoutbube from "../../components/Video/video";
import Image from "../../components/ImageCousel/Image";
import logoTC  from './../../Images/20240112_121648.jpg'


import About from "../About/About";
import Footer from "../../components/Footer/Footer";

import ScrollFadeIn from "../../components/ScrollFadeIn";

import song1 from "./../../Songs/Advent Sounds.mp3"
import song2 from "./../../Songs/HEAVENLY ECHOES.mp3"
import song3 from "./../../Songs/Kando ya Bahari.mp3"
import song4 from "./../../Songs/Zion_Trumpets.mp3"
import Chair  from './../../Images/20230429_162817-removebg-preview.jpg'

import Vice  from './../../Images/255692681669_status_425db413c528466f9c499919c162552f.jpg'
import Secretary  from './../../Images/IMG-20240225-WA0032.jpg'
import treasure  from './../../Images/costa.jpg'
import deputySec  from './../../Images/theresia.jpg'
import deputyTr  from './../../Images/esteri.jpg'
import love  from './../../Images/IMG-20240225-WA0010.jpg'
import value  from './../../Images/IMG-20240225-WA0011.jpg'
const Home =()=>{
//   const playList=[
// {name:"Advent Sounds", url:song2},
// {name:"HEAVENLY ECHOES", url:song1},
// {name:"Kando ya Bahari", url:song3},
// {name:"Zion_Trumpets", url:song4},

//   ];

const [srollHeight,setScrollHeight]=useState(0);
const [lineHeight,setLineHeight]=useState(0)
useEffect(()=>{
  const handleScroll=()=>{
    const newScrollHeight=window.scrollY;
    setScrollHeight(newScrollHeight)

    const targetDiv=document.getElementById('targetDiv');
    if(targetDiv){
      const targetDivTop=targetDiv.offsetTop;
      const targetDivHeight=targetDiv.offsetHeight;
      const scrollPositionRelativeToDiv=newScrollHeight-targetDivTop;
      
      const speedFactor=1;

      const visibleHeight=Math.max(targetDivHeight-scrollPositionRelativeToDiv,0)
      // const newLineHeight=Math.min(Math.max(scrollPositionRelativeToDiv*speedFactor,0),
      // targetDivHeight);
      const lineVisibleHeight=Math.min(visibleHeight,targetDivHeight,
        Math.max(scrollPositionRelativeToDiv,0));
      setLineHeight(lineVisibleHeight)
    }

 
  };
  window.addEventListener('scroll',handleScroll);
  return ()=>{
    window.removeEventListener('scroll',handleScroll)
  }
},[])
  const LeadersData=[
    {
        id:"1",
        image:Chair,
        position:"Chairperson at TucasaCive",
        name:'Chesco Mwangoka'
    }, {
        id:"2",
        image:Vice,
        position:"Vice Chairperson at TucasaCive",
        name:"Rachel Raphael"
    }, {
        id:"3",
        image:Secretary,
        position:" Executive Secretary at Tucasa Cive ",
        name:"Samwel Ngusa"
    }, {
        id:"4",
        image:deputySec,
        position:"Deputy Executive Secretary at Tucasa Cive",
        name:"Theresia Meena"
    },{
      id:"4",
      image:treasure,
      position:"Treasure at Tucasa Cive",
      name:"Costance Boniface"
  },{
    id:"4",
    image:deputyTr,
    position:"Deputy Treasure at Tucasa Cive",
    name:"Esther Manenge"
}
]
 return (

  <div className="content">
     
  <div className="img" >
  <div className="welcome">
      Unleashing the Potential of Student Unity
  </div>
  <Image/>
  </div>
  {/* <div className="music">
  <Music playlist={playList} />
  </div> */}
    <div className="Home-bout" id="targetDiv" style={{marginTop:"10px"}}>
    
        <div style={{
          position:'fixed',
          left:"30px",
          top:"0",
          height:`${lineHeight}px`,
          width:'5px',
          background:"#ccc"
        }}>

          
        </div>
   
   
    <div className="bout-welcome-content" style={{marginLeft:"10px"}}>   
              <ScrollFadeIn >
              <h2>Welcome to Tucasa Cive</h2>
                 
                 <p>
                     At Tucasa Cive, we are a vibrant community of believers dedicated to living out the teachings of Jesus Christ. Our Adventist faith shapes who we are, influencing our worship, our relationships, and our service to others.
                 </p>
              
                 </ScrollFadeIn>
              
                 </div>

              <div className="bout-ourMission">
              <ScrollFadeIn >
            <h2>Our Mission</h2>
            <p>Make disciples of Jesus Christ who live as His loving witness and proclaim to all people the everlasting gospel of the Three Angels' Messages in preparation for His soon return (Matt 28:18-20,Acts 1:8,Rev 14:6-12)</p>
            <span >
                 <Link className="more-button" to="/about-us">More</Link>
                 </span>
              </ScrollFadeIn>
              </div>
              <div className="bout-VisitUs">

              </div>
    </div>
    <div className="leaders">
      <h1 className="leader">Our Leaders</h1>
   <div className="leader-image-section">
   {
      LeadersData.map((leader)=>{
          return(
              <div className="leaders-container" key={leader.id}>
          <div className="leader-image">
            {
              <img  src={leader.image}  />
      }
      </div>
      <div className="leader-name">
        <div className="name">{leader.name}</div>
        <div className="position">{leader.position}</div>
        </div>

          </div>
          )

      })

  }
   </div>



    </div>
    {/* <div className="" >
<myYoutbube/>
</div> */}

    <div className="Links">
                <div className="link">
                       <h1>Read Now</h1>
               </div>
                   <div className="lesson">
                        <p>Sabbath School Lesson Quaertery Read Here:</p>
                              <a href="https://ssnet.org/lessons/24a/" target="_blank" rel="noopener noreferrer">
                                         <FontAwesomeIcon icon={faBook} size="lg" color="#00315b"/> Read Here
                              </a>
                   </div>
   
                    <div className="conference">
                             <h4>General Conference:</h4>
                                <p>Get the Latest Updates from Our Seventh Day Adventist Church general Conference </p>
                                     <a href="http://www.gc.adventist.org"> <FontAwesomeIcon icon={faSync} size="lg" color="#00315b"/>GC Website</a>
                     </div>
                  <div className="conference">
                             <h4>Tucasa-STU:</h4>
                                <p>visit the This site below for more updates from Tucasa STU : </p>
                                        <a href="http://www.tucasastu.com"> <FontAwesomeIcon icon={faSync} size="lg" color="#00315b"/>TucasaSTU</a>
                   </div>
                   <div className="conference">
                          <h4>Tucasa-CTF:</h4>
                              <p>visit the This site below for more updates from Tucasa CTF: </p>
                                  <a href="http://www.tucasactf.or.tz"> <FontAwesomeIcon icon={faSync} size="lg" color="#00315b"/>TucasaCTF</a>
                    </div>
   </div> 
              <div className="Tucasa-brand-logo">
                     <h1 className="mini">Ministries</h1>
                            <div className="section-one">
                                    <div className="pcm">
                                         <div className="pcmImage">
                                                <img src={love} alt="" />
                                         </div>
    
                                        <div className="pcm-word">
                                          <h2>Public Campus Ministries</h2> 
                                               <ScrollFadeIn>
                                                     <p>This Ministry is typically engaging the students on college campuses,fostering spiritual growth,providing  support and creating a sense of community</p>
                                                </ScrollFadeIn>
                                        </div>
                                  </div>
                                  <div className="value-love">
                                        <div className="value-img">
                                               <img src={logoTC} alt="" />
                                        </div>
                                     <div className="Tcive-word">
                                            <h2>Tucasa cive</h2>
                                                 <ScrollFadeIn>
                                                     <p>Cive community  always extends compansion and kindness to others,refrecting love of Jesus christ also embacing the hope  found in the promises of God and maintain the consistence and sincere prayer life</p>
                                                            <Link to="/about-us" style={{textDecoration:"none",padding:"5px", border:"1px solid #00315b",borderRadius:"15px"}}>
                                                                  <span>More <FontAwesomeIcon  icon={faArrowRight} color=" #00315b"/></span>
                                                            </Link> 
                                                </ScrollFadeIn>
                                      </div>
                                  </div>
                             </div>
               </div>

      <div className="visitUs">
                 <div className="visitHeader">
                        <h1>Visit Us</h1>
                 </div>
                 <div className="visitContent">
                        <ul>
                      <li>
                           <FontAwesomeIcon icon={faPhone} color="#00315b" style={{marginRight:"15px"}}/>
                                 Phone (+255) 682 827 536
                      </li>
                      <li>
                            <FontAwesomeIcon icon={faMapMarker} color="#00315b"  style={{marginRight:"15px"}}/>
                                  Block 6 side A- Second Floor
                      </li>
                      <li>
                            <FontAwesomeIcon icon={faEnvelope} color="#00315b" style={{marginRight:"15px"}}/>
                                 info@Tucasacive.com
                                      <span>
                                           <ul>
                                                  <li style={{fontWeight:"bolder"}}>Worship with us</li>
                                                  <li style={{fontWeight:"bolder"}}>Pray with Us</li>
                                                  <li style={{fontWeight:"bolder"}}>Collaborate with Us</li>
                                           </ul>
                                       </span>
                      </li>
                </ul>
                            <div className="brand-icons">
                                     <a href="https://www.youtube.com/channel/UCPM1SzyxuTq5vf95ngqgWuA" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faYoutube} size="2x" color="#00315b"/>
                                     </a>


                                      <a href="https://www.instagram.com/tucasa_cive/" target="_blank" rel="noopener noreferrer">
                                          <FontAwesomeIcon icon={faInstagram} size="2x" color="#00315b"/>
                                      </a>


                                       <a href="https://x.com/Tucasa_Cive?t=gJjILYd0KIwd5z-w3_7GoQ&s=09" target="_blank" rel="noopener noreferrer">
                                           <FontAwesomeIcon icon={faXTwitter} size="2x" color="#00315b"/>
                                       </a>

                            </div>
                 {/* <div className="innovation">
                           <h2 style={{fontWeight:"300", textDecoration:"underline"}}>Innovations & Skills</h2>
                                <div>
                                    <ul>
                                        <li style={{listStyle:"inside",fontWeight:"lighter"}}>meet our expert Mr simon for mmore graphics designing Tips  </li>
                                           <li style={{listStyle:"inside"}}>Learn Python Flask deep from Sratch from our Brother @Vincent laizer</li>
                                             <li style={{listStyle:"inside"}}>Be expert in case of cyber security skills from Our Brother Baraka</li>
                                     </ul>
                                 </div>
                  </div> */}
     </div>
    
    </div>
    
    <div className="footer">
        <span>
                Copyright &copy; 2024 Tucasa Cive
        </span>
    </div>
</div>

 )
}
export default Home
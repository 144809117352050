import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'

function ContributionAnalysis() {
    const auth = useAuth()

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [contributions, setContributions] = useState([])
    const [contribution, setContribution] = useState("")
    const [analysis, setAnalysis] = useState(null)

    const columns = [
		{
			name: 'First Name',
			selector: row => row.firstname,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: row => row.lastname,
			sortable: true,
		},
		{
			name: 'Phone',
			selector: row => row.phone,
			sortable: false,
			grow: 2,
		},
		{
			name: 'Gender',
			selector: row => row.gender,
			sortable: true,
		},
		{
			name: 'YOS',
			selector: row => row.yos,
			sortable: true,
		},
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        }
	]

    const fetchContributions = async () => {
        setIsLoading(true)
        setMessage('Loading...')

        try {
            const response = await fetch(`${BASE_API_URL}/contributions`, {
                method: 'GET',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const result = await response.json()
            if (response.ok) {
                setContributions(result.contributions)
                setMessage('')
            }
            else {
                setMessage(result.message)
                setVariant('danger')
            }
        }
        catch (error) {
            setMessage('Something went wrong. Try again.')
            setVariant('danger')
        }
        setIsLoading(false)
    }

    const fetchAnalysis = async (id) => {
        setIsLoading(true)
        setMessage('Loading...')

        try {
            const response = await fetch(`${BASE_API_URL}/contributions/${id}/analysis`, {
                method: 'GET',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const result = await response.json()
            if (response.ok) {
                setAnalysis(result.analysis)
                setMessage('')
            }
            else {
                setMessage(result.message)
                setVariant('danger')
                setAnalysis(null)
            }
        }
        catch (error) {
            setMessage('Something went wrong. Try again.')
            setVariant('danger')
            setAnalysis(null)
        }
        setIsLoading(false)
    }

    const contributionChange = async (id) => {
        setContribution(id)
        if (id){
            fetchAnalysis(id)
        }
    }

    useEffect(() => {
        fetchContributions()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Contribution Analysis</p>
                <Link to={'member'} className='btn btn-sm btn-primary fw-bold'>
                    By Member Analysis
                </Link>
            </div>
            <div className='shadow rounded my-3 p-2'>
                {
                    message && <div className={`alert alert-${variant} p-2`}>{message}</div>
                }

                <div>
                    <div className="mb-4">
                        <label htmlFor="contribution" className="form-label">Select Contribution</label>
                        <select
                            className="form-select"
                            name="contribution"
                            id="contribution"
                            required
                            value={contribution}
                            onChange={e => contributionChange(e.target.value)}
                        >
                            <option value="">Select Contribution</option>
                            {
                                contributions.map(c => (
                                    <option value={c.id} key={c.id}>
                                        {c.name} | &nbsp;
                                        {c.amount.toLocaleString()} {c.type === "Per Person" ? " / person" : ""} | &nbsp;
                                        {c.status}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    {
                        analysis ?
                            <div>
                                <div className="row mb-3">
                                    <div className="col-12 col-lg-8 border-end mx-0 row mb-3">
                                        {
                                            analysis.tiles.map((t, i) => (
                                                <div 
                                                    className="col-12 col-lg-3 m-1 p-2 border rounded d-flex flex-column justify-content-between" 
                                                    key={i}
                                                >
                                                    <p className='fw-bold text-muted'>{t.title}</p>
                                                    <div className='text-end fw-bold h5'>{t.value}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                        Pie chart showing the distribution
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <p className='fw-bold'>Members that have contributed</p>
                                    <DataTable
                                        columns={columns}
                                        data={analysis.members}
                                        highlightOnHover
                                        pagination
                                        paginationPerPage={20}
                                        noDataComponent={"No members have contributed"}
                                    />
                                </div>
                            </div>
                            :
                            <div className='text-center'>
                                No analysis yet
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ContributionAnalysis

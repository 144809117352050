import React, { useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'

function NewMember() {
	const auth = useAuth()

	const [isLoading, setisLoading] = useState(false)
	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')
	const [phoneError, setPhoneError] = useState(true)

	const [firstName, setFirstName] = useState('')
	const [middleName, setMiddleName] = useState('')
	const [lastName, setLastName] = useState('')
	const [gender, setGender] = useState('')
	const [baptism, setBaptism] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [residence, setResidence] = useState('')
	const [yos, setYos] = useState('')
	const [dob, setDob] = useState('')
	const [program, setProgram] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		setisLoading(true)

		const data = {
			firstname: firstName,
			middlename: middleName,
			lastname: lastName,
			gender: gender,
			baptism: baptism,
			phone: phoneNumber,
			email: email,
			residence: residence,
			yos: yos,
			dob: dob,
			program: program,
			role: "MEMBER",
			password: lastName
		}

		try {
			const res = await fetch(`${BASE_API_URL}/member`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-access-token": auth.token
				},
				body: JSON.stringify(data)
			})

			const response = await res.json()
			if (!res.ok) {
				setMessage(response.message)
				setVariant('danger')
			}
			else {
				setMessage(response.message)
				setVariant('success')
			}
			setisLoading(false)
		}
		catch (error) {
			setMessage("An error occured while registering member")
			setVariant('danger')
			setisLoading(false)
		}
	}

	return (
		<div className='pt-2'>
			<p className='h3 fw-bold text-muted mb-3'>New Member</p>
			<div className='shadow rounded p-2'>
				{
					message && 
                    <div className={`alert alert-${variant}`} role="alert">
						{message}
					</div>
				}
				<form onSubmit={handleSubmit}>
					<p className='h4 mb-3 text-muted'>Basic Info</p>
					<div className='row'>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='firstName'>First Name</label>
								<input
									type='text'
									className='form-control'
									id='firstName'
									required
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='middleName'>Middle Name</label>
								<input
									type='text'
									className='form-control'
									id='middleName'
									required
									value={middleName}
									onChange={(e) => setMiddleName(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='lastName'>Last Name</label>
								<input
									type='text'
									className='form-control'
									id='lastName'
									required
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='gender'>Gender</label>
								<select
									className='form-select'
									id='gender'
									required
									value={gender}
									onChange={(e) => setGender(e.target.value)}
								>
									<option value="">Select Gender</option>
									<option value="Female">Female</option>
									<option value="Male">Male</option>
								</select>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='dob'>Date of Birth</label>
								<input
									type='date'
									className='form-control'
									id='dob'
									required
									value={dob}
									onChange={(e) => setDob(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='baptism'>Baptisim</label>
								<select
									className='form-select'
									id='baptism'
									required
									value={baptism}
									onChange={(e) => setBaptism(e.target.value)}
								>
									<option value="">Select Baptism Status</option>
									<option value="Baptised">Baptised</option>
									<option value="Not Baptised">Not Baptised</option>
								</select>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<p className='h4 mb-3 text-muted'>Contact Info</p>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='phone'>Phone Number (255...)</label>
								<input
									type='text'
									className='form-control'
									id='phone'
									required
									value={phoneNumber}
									onChange={(e) => {
										// test phonenumber with regex: 255(6|7)[0-9]{8}
										if (!(/^255(6|7)[0-9]{8}$/.test(e.target.value))){
											setPhoneError(true)
										}
										else{
											setPhoneError(false)
										}
										setPhoneNumber(e.target.value)}
									}
								/>
								{
									phoneError &&
									<small className="text-danger">Enter a valid phone number</small>
								}
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='email'>Email Address (Username)</label>
								<input
									type='email'
									className='form-control'
									id='email'
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='residence'>Residence</label>
								<input
									type='text'
									className='form-control'
									id='residence'
									required
									value={residence}
									onChange={(e) => setResidence(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<p className='h4 mb-3 text-muted'>Academic Info</p>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='yos'>Year of Study</label>
								<select
									className='form-select'
									id='yos'
									required
									value={yos}
									onChange={(e) => setYos(e.target.value)}
								>
									<option value="">Select Year of Study</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
								</select>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='program'>Program</label>
								<input
									type='text'
									className='form-control'
									id='program'
									required
									value={program}
									onChange={(e) => setProgram(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<button type='submit' className='btn btn-primary px-4' disabled={phoneError}>
						{
							isLoading ? "Registering..." : "Register"
						}
					</button>
				</form>
			</div>
		</div>
	);
}

export default NewMember
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import { useParams } from 'react-router'
import BASE_API_URL from '../../../utils/constants'

function ViewContribution() {
    const auth = useAuth()
    const params = useParams()
    const contributionId = params.contributionId

    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('info')
    const [isLoading, setIsLoading] = useState(false)
    const [enabled, setEnabled] = useState(false) // editing enabled

    const [name, setName] = useState('')
    const [amount, setAmount] = useState('')
    const [description, setDescription] = useState('')
    const [type, setType] = useState("Total")
    const [status, setStatus] = useState("Active")
    const [dateCreated, setDateCreated] = useState('')
    const [createdBy, setCreatedBy] = useState('')

    const fetchContribution = async () => {
        setIsLoading(true)
        setMessage('Loading...')

        try {
            const response = await fetch(`${BASE_API_URL}/contributions/${contributionId}`, {
                method: 'GET',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const result = await response.json()
            if (response.ok) {
                const c= result.contribution
                setName(c.name)
                setAmount(c.amount)
                setDescription(c.description)
                setType(c.type)
                setStatus(c.status)
                setDateCreated(c.date_created)
                setCreatedBy(`${c.created_by.firstname} ${c.created_by.lastname}`)
                setMessage("")
            }
            else {
                setMessage(result.message)
                setVariant('danger')
            }
        }
        catch (error) {
            setMessage('Something went wrong. Try again.')
            setVariant('danger')
        }
        setIsLoading(false)
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage("")

        const data = {
            name,
            amount,
            description,
            type,
            status
        }

        try {
            const response = await fetch(`${BASE_API_URL}/contributions/${contributionId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify(data)
            })

            const result = await response.json()
            if (response.ok) {
                setMessage(result.message)
                setVariant('success')
                const contrib = result.contribution
                setName(contrib.name)
                setAmount(contrib.amount)
                setDescription(contrib.description)
                setType(contrib.type)
                setStatus(contrib.status)
                setEnabled(false)
            }
            else {
                setMessage(result.message)
                setVariant('danger')
            }
        }
        catch (error) {
            setMessage("Something went wrong. Try again.")
            setVariant("danger")
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchContribution()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>{name}</p>
                <button
                    className='btn btn-sm btn-primary fw-bold'
                    onClick={() => setEnabled(!enabled)}
                >
                    Edit <i className='bi bi-pencil-square ms-2'></i>
                </button>
            </div>
            <div className='shadow rounded my-3 p-2'>
                {
                    message && <div className={`alert alert-${variant} p-2`}>{message}</div>
                }

                {
                    !isLoading &&
                    <form onSubmit={handleUpdate}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Contribution Name</label>
                            <input
                                type='text'
                                className='form-control'
                                name='name'
                                id='name'
                                placeholder='Contribution Name'
                                value={name}
                                onChange={e => setName(e.target.value)}
                                disabled={!enabled}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="amount" className="form-label">Amount (Tsh)</label>
                            <input
                                type='text'
                                className='form-control'
                                name='amount'
                                id='amount'
                                placeholder='10000.00'
                                value={amount}
                                onChange={e => setAmount(e.target.value)}
                                disabled={!enabled}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="type" className="form-label">Amount is per person?</label>
                            <span className='mx-1'>
                                {type === "Per Person"?"Yes":"No "}
                            </span>
                            <input
                                type='checkbox'
                                class="form-check-input ms-2"
                                name='type'
                                id='type'
                                checked={type === "Per Person"}
                                onChange={e => (
                                    e.target.checked ? setType("Per Person") : setType("Total")
                                )}
                                disabled={!enabled}
                            />
                        </div>                        
                        <div className="mb-3">
                            <label htmlFor="amount" className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                name="description"
                                id="description"
                                rows="3"
                                placeholder='A short description of the contribution'
                                onChange={e => setDescription(e.target.value)}
                                disabled={!enabled}
                                required>{description}
                            </textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status" className="form-label">Status</label>
                            <select 
                                className="form-select" 
                                name="status" 
                                id="status" 
                                value={status} 
                                onChange={e => setStatus(e.target.value)}
                                disabled={!enabled}
                            >
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="creator" className="form-label">Created By</label>
                            <input
                                type="text"
                                className="form-control"
                                name="creator"
                                id="creator"
                                placeholder='Created By'
                                value={createdBy}
                                onChange={e => setCreatedBy(e.target.value)}
                                disabled
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="date" className="form-label">Date Created</label>
                            <input
                                type="date"
                                className="form-control"
                                name="date"
                                id="date"
                                placeholder='Date Created'
                                value={dateCreated}
                                onChange={e => setDateCreated(e.target.value)}
                                disabled
                                required
                            />
                        </div>
                        <div className="mb-3 text-end">
                            <button type="submit" className="btn btn-primary px-4" disabled={!enabled}>
                                {
                                    isLoading ? 'Updating...' : 'Update'
                                }
                            </button>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}

export default ViewContribution

import React from "react";
import "./About.css"
import prayer from "./../../Images/sdalogo2.jpg"
import ScrollFadeIn from "../../components/ScrollFadeIn";
const About =()=>{
 return (
    <div className="About">
               <div className="about-container1">
                   <div className="about-image-container">
                     <img src={prayer} className="background-image" />
                    <div className="about-overlay">
                    </div>
                   </div>
                   <div className="total-about">
                    
                    <div className="About-welcome-content">   
                    <ScrollFadeIn >
                    <h2>Welcome to Tucasa Cive</h2>
                       
                       <p>
                           At Tucasa Cive, we are a vibrant community of believers dedicated to living out the teachings of Jesus Christ. Our Adventist faith shapes who we are, influencing our worship, our relationships, and our service to others.
                       </p>
                       </ScrollFadeIn>
                    
                       </div>
                       <div className="About-ourBelief">

                    </div>  
                    <div className="About-ourMission">
                    <ScrollFadeIn >
                  <h2>Our Mission</h2>
                    <p>Make disciples of Jesus Christ who live as His loving witness and proclaim to all people the everlasting gospel of the Three Angels' Messages in preparation for His soon return (Matt 28:18-20,Acts 1:8,Rev 14:6-12)</p>
                    </ScrollFadeIn>
                    </div>
                    <div className="About-VisitUs">
                    <ScrollFadeIn >
<h2>Visit Us</h2>
<p>We invite you to experience the warmth of our community and the transformative power of faith. we Found at <b>Common Room Block 6 side A</b></p>
 </ScrollFadeIn>
                    </div>
                    <div className="About-VisitUs">
                    <ScrollFadeIn >
<h2>Our History</h2>
<p>We invite you to experience the warmth of our community and the transformative power of faith. we Found at <b>Common Room Block 6 side A</b></p>
 </ScrollFadeIn>
                    </div>
                    </div>
                 
                 
                   
                
               </div>
              
                
                   
        </div>
       
 )
}
export default About
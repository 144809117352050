import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'
import { useParams } from 'react-router'

function Archive() {
    const auth = useAuth()
    const params = useParams()
    const archiveId = params.archiveId

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [document, setDocument] = useState("")
    const [uploadedBy, setUploadedBy] = useState()
    const [date, setDate] = useState("")

    const fetchArchive = async () => {
        try {
            const res = await fetch(`${BASE_API_URL}/archives/${archiveId}`, {
                headers: {
                    "x-access-token": auth.token
                }
            })
            const data = await res.json()

            if (!res.ok) {
                setMessage(data.message)
                setVariant("danger")
            }

            setTitle(data.archive.title)
            setDescription(data.archive.description)
            setDocument(data.archive.document)
            setUploadedBy(data.archive.uploaded_by)
            setDate(new Date(data.archive.date_created).toISOString().split('T')[0])
        } catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
    }

    useEffect(() => {
        fetchArchive()
    }, [])

    return (
        <>
            <div className='pt-2'>
                <p className='h3 fw-bold text-muted'>View Document</p>
            </div>
            <div className="shadow rounded p-2 mb-3">
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
                <form action="" encType='multipart/form-data'>
                    <div className='form-group mb-3'>
                        <label htmlFor='title'>Document Title</label>
                        <input
                            type='text'
                            className='form-control'
                            id='title'
                            required
                            readOnly
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor='uploader'>Uploaded By</label>
                        <input
                            type='text'
                            className='form-control'
                            id='uploader'
                            required
                            readOnly
                            value={ 
                                uploadedBy?
                                `${uploadedBy.firstname} ${uploadedBy.lastname}`:""
                            }
                        />
                    </div>
                    <div className='form-group mb-3'>
                        <label htmlFor='date'>Uploaded On</label>
                        <input
                            type='date'
                            className='form-control'
                            id='date'
                            required
                            readOnly
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="description" className="form-label">Document Description</label>
                        <textarea
                            className="form-control"
                            name="description"
                            id="description"
                            rows="3"
                            required
                            readOnly
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}>
                        </textarea>
                    </div>
                    <div className="text-end mb-3">
                        <a href={`${BASE_API_URL}/${document}`} className="btn btn-primary px-4" target="_blank">
                            Preview File
                            <i className="bi bi-eye ms-2" />
                        </a>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Archive

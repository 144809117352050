import React, { useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'
import { useLocation } from 'react-router'

function EditAnnouncement() {
    const auth = useAuth()
    const location = useLocation()
    const announcement = location.state.announcement

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [title, setTitle] = useState(announcement.title || "")
    const [description, setDescription] = useState(announcement.description || "")
    const [visibility, setVisibility] = useState(announcement.visibility || "")
    const [image, setImage] = useState(`${BASE_API_URL}${announcement.image}` || "")

    const handleSubmit = async (e) => {
        e.preventDefault()
        setMessage("")
        setIsLoading(true)

        const formData = new FormData()
        formData.append('title', title)
        formData.append('description', description)
        formData.append('visibility', visibility)
        // dont send image if its not changed
        if (!image.toString().startsWith("http")){
            formData.append('image', image)
        }

        try {
            const res = await fetch(`${BASE_API_URL}/announcements/${announcement.id}/edit`, {
                method: "PUT",
                headers: {
                    "x-access-token": auth.token
                },
                body: formData
            })

            const response = await res.json()
            if (!res.ok) {
                setMessage(response.message)
                setVariant("danger")
            }
            else {
                setMessage(response.message)
                setVariant("success")
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
        setIsLoading(false)
    }

    return (
        <>
            <div className='pt-2'>
                <p className='h3 fw-bold text-muted'>Edit Announcement</p>
            </div>
            <div className="shadow rounded p-2 mb-3">
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
                <form encType='multipart/form-data' onSubmit={handleSubmit}>
                    <div className='form-group mb-3'>
                        <label htmlFor='title'>Title</label>
                        <input
                            type='text'
                            className='form-control'
                            id='title'
                            required
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                            className="form-control"
                            name="description"
                            id="description"
                            rows="3"
                            required
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}>
                        </textarea>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="visibility" className="form-label">Visibility</label>
                        <select
                            className="form-select"
                            id="visibility"
                            required
                            value={visibility}
                            onChange={(e) => setVisibility(e.target.value)}
                        >
                            <option value="Public">Public</option>
                            <option value="Private">Private</option>
                        </select>
                    </div>
                    <div className="form-group mb-3 cursor-pointer">
                        <label htmlFor="image" className="form-label w-100">
                            <div>Image</div>
                            <div className='mb-2 text-center'>
                                {
                                    image ?
                                        <span style={{ position: "relative" }}>
                                            <img
                                                src={
                                                    image.toString().startsWith("http") ?
                                                        image
                                                        :
                                                        URL.createObjectURL(image)
                                                }
                                                alt="announcement poster"
                                                className="img-fluid img-thumbnail"
                                                style={{ maxHeight: "200px" }}
                                            />
                                            <button
                                                className='btn btn-sm btn-danger'
                                                style={{ position: "absolute", right: 0 }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setImage("")
                                                }}
                                            >
                                                <i className='bi bi-x-lg'></i>
                                            </button>
                                        </span> :
                                        <span className="bi bi-image h1 text-muted"></span>
                                }
                            </div>
                            <div>
                                <small className="form-text text-muted">Only image files are allowed</small>
                                <br />
                                <small className="form-text text-muted">Max file size: 2MB</small>
                            </div>
                        </label>
                        <input
                            type="file"
                            className="form-control"
                            id="image"
                            name="image"
                            accept="image/*"
                            hidden
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div >
                    {
                        message &&
                        <div className={`alert alert-${variant} p-2`} role="alert">
                            {message}
                        </div>
                    }
                    <div className="text-end mb-3">
                        <button type="submit" className="btn btn-primary px-4">
                            {
                                isLoading ? "updating..." : "Update"
                            }
                        </button>
                    </div>
                </form >
            </div >
        </>
    )
}

export default EditAnnouncement

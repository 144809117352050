import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import { useParams } from 'react-router'
import BASE_API_URL from '../../../utils/constants'
import { Link } from 'react-router-dom'
import ChairpersonsOffice from '../ChairpersonsOffice'

function Announcement() {
    const auth = useAuth()
    const param = useParams()
    const announcementId = param.announcementId

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")

    const [announcement, setAnnouncement] = useState({})

    const fetchAnnouncement = async () => {
        setMessage("")

        try {
            const res = await fetch(`${BASE_API_URL}/announcements/${announcementId}`, {
                headers: {
                    "x-access-token": auth.token
                }
            })
            const data = await res.json()

            if (!res.ok) {
                setMessage(data.message)
                setVariant("danger")
            }
            else {
                setAnnouncement(data.announcement)
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
    }

    const toggleVisibility = async () => {
        setMessage("")

        try {
            const res = await fetch(`${BASE_API_URL}/announcements/${announcementId}/visibility`, {
                method: "PUT",
                headers: {
                    "x-access-token": auth.token
                }
            })
            const data = await res.json()

            if (!res.ok) {
                setMessage(data.message)
                setVariant("danger")
            }
            else {
                setAnnouncement(data.announcement)
                setMessage(data.message)
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
    }

    const toggleStatus = async () => {
        setMessage("")

        try {
            const res = await fetch(`${BASE_API_URL}/announcements/${announcementId}/status`, {
                method: "PUT",
                headers: {
                    "x-access-token": auth.token
                }
            })
            const data = await res.json()

            if (!res.ok) {
                setMessage(data.message)
                setVariant("danger")
            }
            else {
                setAnnouncement(data.announcement)
                setMessage(data.message)
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
    }

    useEffect(() => {
        fetchAnnouncement()
    }, [])

    return (
        <div>
            <div className='pt-2 mb-3'>
                <p className='h3 fw-bold text-muted'>View Announcement</p>
            </div>
            <div className="shadow rounded mb-3">
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
                <div>
                    {
                        announcement.image &&
                        <img
                            src={`${BASE_API_URL}/${announcement.image}`}
                            alt={announcement.title}
                            style={{ width: "100%", height: "auto", objectFit: "cover", maxHeight: "390px" }}
                        />
                    }
                </div>
                <div className="p-3">
                    <p className='h4 fw-bold'>{announcement.title}</p>
                    <p className='text-muted'>{new Date(announcement.created_at).toLocaleString()}</p>
                    <p>{announcement.description}</p>
                    <ChairpersonsOffice>
                        <div className='my-3 text-center'>
                            <Link to={`edit`} state={{ announcement: announcement }} className='btn btn-sm btn-primary fw-bold me-2 mb-2 px-3'>
                                <i className='bi bi-pencil-square me-2'></i>
                                Edit
                            </Link>
                            <button className='btn btn-sm btn-danger fw-bold me-2 mb-2 px-3' onClick={toggleVisibility}>
                                {
                                    announcement.visibility === "Private" ?
                                        <>
                                            <i className='bi bi-eye-slash me-2'></i>
                                            Make Public
                                        </>
                                        :
                                        <>
                                            <i className='bi bi-eye me-2'></i>
                                            Make Private
                                        </>
                                }
                            </button>
                            <button className='btn btn-sm btn-warning fw-bold me-2 mb-2 px-3' onClick={toggleStatus}>
                                {
                                    announcement.status === "Active" ?
                                        <>
                                            <i className='bi bi-pause me-2'></i>
                                            Deactivate
                                        </>
                                        :
                                        <>
                                            <i className='bi bi-play me-2'></i>
                                            Activate
                                        </>
                                }
                            </button>
                        </div>
                    </ChairpersonsOffice>
                </div>
            </div>
        </div>
    )
}

export default Announcement

import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router'
import SideBar from './components/sidebar/SideBar'
import NavBar from './components/navbar/NavBar'
import Navbar from '../../components/NavBar/Navbar'
import { useAuth } from '../../utils/AuthContext'
import Login from '../Login/Login'

function OnBoarding() {
  const auth = useAuth()

  if (!auth.isLoggedIn()) {
    return (
      <>
        <Navbar />
        <Login msg={"Please login to access this page"} />
      </>
    )
  }

  return (
    <div className='home' id='body-pd'>
      <NavBar />
      <SideBar />
      <div className="">
        <Outlet />
      </div>
    </div>
  )
}

export default OnBoarding

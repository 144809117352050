import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import { useLocation, useParams } from 'react-router'
import BASE_API_URL from '../../../utils/constants'
import { Link } from 'react-router-dom'

function ContributionMemberAnalysis() {
    const auth = useAuth()
    const params = useParams()
    const memberId = params.memberId
    const location = useLocation()
    const member = location.state.member

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [analysis, setAnalysis] = useState(null)

    const fetchAnalysis = async () => {
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/contributions/analysis/${memberId}`, {
                method: 'GET',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const result = await response.json()
            if (response.ok) {
                setAnalysis(result.analysis)
                setMessage('')
            }
            else {
                setMessage(result.message)
                setVariant('danger')
                setAnalysis(null)
            }
        }
        catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchAnalysis()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 mb-2 fw-bold text-muted'>Member Contribution Analysis</p>
            <div className="mb-3 mx-2">
                <strong>{member.firstname} {member.lastname}</strong>
            </div>
            <div className='shadow rounded mb-3 p-2'>
                {
                    message && <div className={`alert alert-${variant} p-2`}>{message}</div>
                }

                <div className="mb-3">
                    <p className='text-muted fw-bold'>Contributions Made</p>
                    {
                        analysis &&
                            analysis.contributions.length > 0 ?
                            analysis.contributions.map((c, i) => (
                                <div key={i} className='mb-3'>
                                    <div className="d-flex justify-content-between mb-2">
                                        <span>{c.name}</span>
                                        <Link to={'history'} state={{member: member, contribution: c}} className=''>
                                            History
                                        </Link>
                                    </div>
                                    <div className="progress" style={{height: "24px"}}>
                                        <div
                                            className="progress-bar bg-primary"
                                            role="progressbar"
                                            style={{width: `${c.percent}%`}}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {c.percent}%
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between m-1 border-bottom pb-2">
                                        <span>{c.total.toLocaleString()} Tsh</span>
                                        <span>{c.amount.toLocaleString()} Tsh</span>
                                    </div>
                                </div>
                            ))
                            :
                            <div className="text-center">
                                No contributions made.
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ContributionMemberAnalysis

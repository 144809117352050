import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import { useLocation, useNavigate, useParams } from 'react-router'
import BASE_API_URL from '../../../utils/constants'

function RecordContribution() {
    const auth = useAuth()
    const params = useParams()
    const memberId = params.memberId
    const location = useLocation()
    const member = location.state.member

    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('danger')
    const [isLoading, setIsLoading] = useState(false)

    const [contributions, setContributions] = useState([])
    const [contribution, setContribution] = useState("")
    const [amount, setAmount] = useState("")

    const fetchActiveContributions = async () => {
        setIsLoading(true)
        setMessage('Loading...')

        try {
            const response = await fetch(`${BASE_API_URL}/contributions/active`, {
                method: 'GET',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const result = await response.json()
            if (response.ok) {
                setContributions(result.contributions)
                setMessage('')
            }
            else {
                setMessage(result.message)
                setVariant('danger')
            }
        }
        catch (error) {
            setMessage('Something went wrong. Try again.')
            setVariant('danger')
        }
        setIsLoading(false)
    }

    const handleSave = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage("")

        const data = {
            "member": memberId,
            contribution,
            amount
        }

        try{
            const response = await fetch(`${BASE_API_URL}/contributions/record`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify(data)
            })

            const result = await response.json()
            if (response.ok) {
                setMessage(result.message)
                setVariant("success")
            }
            else {
                setMessage(result.message)
                setVariant('danger')
            }
        }
        catch(error){
            setMessage('Something went wrong. Try again.')
            setVariant('danger')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchActiveContributions()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 mb-2 fw-bold text-muted'>Record Contribution</p>
            <div className="mb-3 mx-2">
                Record contributions for <strong>{member.firstname} {member.lastname}</strong>
            </div>
            <div className='shadow rounded mb-3 p-2'>
                {
                    message && <div className={`alert alert-${variant} p-2`}>{message}</div>
                }
                <form onSubmit={handleSave}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Contribution Name</label>
                        <select 
                            className="form-select" 
                            name="name" 
                            id="name" 
                            required
                            value={contribution} 
                            onChange={e => setContribution(e.target.value)}
                        >
                            <option value="">Select Contribution</option>
                            {
                                contributions.map((c) => (
                                    <option value={c.id} key={c.id}>
                                        {c.name} | {c.amount.toLocaleString()} {c.type==="Per Person"?" / person":""}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="amount" className='form-label'>Amount Contributed (Tsh)</label>
                        <input 
                            type="text"
                            className="form-control"
                            name="amount"
                            id="amount"
                            placeholder="1000"
                            pattern='[0-9]*'
                            inputMode='numeric'
                            required
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                        />
                    </div>
                    <div className="mb-3 text-end">
                        <button type="submit" className="btn btn-primary px-4">
                            {
                                isLoading ? 'Recording...' : 'Record'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RecordContribution

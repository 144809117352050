import react from "react-dom"
import { Link } from "react-router-dom"
import "./Register.css"
import { useState } from "react"
import BASE_API_URL from "../../utils/constants"
import { useAuth } from "../../utils/AuthContext"
const Register = () => {
    const auth = useAuth()

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("danger")
    const [phoneError, setPhoneError] = useState(false)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [gender, setGender] = useState("Female")
    const [baptism, setBaptism] = useState("")
    const [phone, setPhone] = useState("")
    const [residence, setResidence] = useState("")
    const [yos, setYos] = useState("")
    const [dob, setDob] = useState("")
    const [program, setProgram] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const response = await fetch(`${BASE_API_URL}/register`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email,
                    firstname: firstName,
                    middlename: middleName,
                    lastname: lastName,
                    gender: gender,
                    baptism: baptism,
                    phone: phone,
                    residence: residence,
                    yos: yos,
                    dob: dob,
                    program: program,
                    password: password
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setMessage(data.message)
                setVariant("danger")
            }
            else {
                setMessage(data.message)
                setVariant("success")
            }
        }
        catch (err) {
            setMessage("An error occured. Please try again.")
            setVariant("danger")
        }
    }

    return (
        <div className="register">
            <div className="form-reg">
                <form className="r-form" onSubmit={handleSubmit}>
                    <h2>TCMS - REGISTER</h2>
                    <div className="message">
                        {message && <div className={`alert alert-${variant}`}>{message}</div>}
                    </div>
                    <div className="name">
                        <label htmlFor="First">
                            <input
                                type="text"
                                placeholder="First Name"
                                id="First"
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </label>
                        <label htmlFor="middlename">
                            <input
                                type="text"
                                placeholder="Middle Name"
                                id="middlename"
                                required
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                            />
                        </label>
                        <label htmlFor="Last">
                            <input
                                type="text"
                                placeholder="Last Name"
                                id="Last"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className="contact">
                        <label htmlFor="Phone">
                            <input
                                type="text"
                                placeholder="Phone Number (255...)"
                                id="phone"
                                required
                                value={phone}
                                onChange={(e) => {
                                    // test phonenumber with regex: 255(6|7)[0-9]{8}
                                    if (!(/^255(6|7)[0-9]{8}$/.test(e.target.value))){
                                        setPhoneError(true)
                                    }
                                    else{
                                        setPhoneError(false)
                                    }
                                    setPhone(e.target.value)}
                                }
                            />
                            {
                                phoneError &&
                                <small className="text-danger">Enter a valid phone number</small>
                            }
                        </label>
                        <label htmlFor="Email">
                            <input
                                type="Email"
                                placeholder="Email Address"
                                id="Email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                        <label htmlFor="dob">
                            <input
                                type="date"
                                placeholder="Date of Birth"
                                id="dob"
                                required
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className="location">
                        <label htmlFor="programme">
                            <input
                                type="text"
                                placeholder="Programme"
                                id="programme"
                                required
                                value={program}
                                onChange={(e) => setProgram(e.target.value)}
                            />
                        </label>
                        <label htmlFor="yos">
                            <select
                                className='form-select'
                                id='yos'
                                required
                                value={yos}
                                onChange={(e) => setYos(e.target.value)}
                            >
                                <option value="">Select Year of Study</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </label>
                        <label htmlFor="Block">
                            <input
                                type="text"
                                placeholder="Residence"
                                id="Block"
                                required
                                value={residence}
                                onChange={(e) => setResidence(e.target.value)}
                            />
                        </label>
                        <label htmlFor="baptism">
                            <select
                                className='form-select'
                                id='baptism'
                                required
                                value={baptism}
                                onChange={(e) => setBaptism(e.target.value)}
                            >
                                <option value="">Select Baptism Status</option>
                                <option value="Baptised">Baptised</option>
                                <option value="Not Baptised">Not Baptised</option>
                            </select>
                        </label>
                    </div>
                    <div className="sign">
                        <div className="gender">
                        <label htmlFor="password">
                                <input
                                    type="password"
                                    placeholder="Password"
                                    id="password"
                                    min={8}
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </label>
                            <div className="gen">
                            <h4>Gender</h4>
                            <div className="male">
                                Male
                                <input
                                    type="radio"
                                    name="gender"
                                    value={"Male"}
                                    checked={gender === "Male"}
                                    onChange={() => setGender("Male")}
                                />
                            </div>
                            <div className="female">
                                Female
                                <input
                                    type="radio"
                                    name="gender"
                                    value={"Female"}
                                    checked={gender === "Female"}
                                    onChange={() => setGender("Female")}
                                />
                            </div>
                            </div>
                          
                           
                           
                         
                        </div>
                      

                    </div>
                    <div className="to-sign">
                            <div className="message">
                                {message && <div className={`alert alert-${variant}`}>{message}</div>}
                            </div>
                            <div>
                            <button type="submit" disabled={phoneError}>SIGN-UP</button>
                            </div>

                            <h4>Already Have an Account? <span><Link to="/login">Log-In</Link></span></h4>
                        </div>
                </form>

            </div>
        </div>
    )
}
export default Register
import React, { useEffect, useState } from "react";
import './Image.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft,faChevronRight} from '@fortawesome/free-solid-svg-icons';
import image1 from './../../Images/IMG-20231113-WA0005.jpg';
import image2 from './../../Images/IMG-20231103-WA0031.jpg';
import image3 from './../../Images/tucasa_cive-20240304-0001.jpg';
import image4 from './../../Images/tucasa_cive-20240304-0003.jpg';
import image5 from './../../Images/tucasa_cive-20240304-0002.jpg';

// import image6 from './../../Images/255785342022_status_bec7693ef6d040aa85dd18ac1a2c0cb8.jpg';

// import image4 from './../../Images/IMG-20231103-WA0023.jpg';



const Image=()=>{
const [currentImageIndex,setCurrentImageIndex]=useState(0);

//array of images
const Images=[
    image1,
    image2,
    image3,
    image4,
    //image5,
    
  
];

const showPreviousImage=()=>{
    setCurrentImageIndex((prevIndex)=>(prevIndex === 0 ? Images.length - 1 : prevIndex - 1));
};

const showNextImage=()=>{
    setCurrentImageIndex((prevIndex) =>(prevIndex === Images.length - 1 ? 0 : prevIndex + 1));
}

useEffect(()=>{
 //interval image in range of 3 seconds
 const intervalid=setInterval(showNextImage,5000);

//  clear the interval on component
 return ()=>
 clearInterval(intervalid);

},[])
    return(
        <div className="Image-pack">
{/* image package */}
            <div className="image-carousel">
             <FontAwesomeIcon icon={faChevronLeft}  color="#00315b"
             onClick={showPreviousImage}
             size="2x"/>
             <img src={Images[currentImageIndex]} alt={`image ${currentImageIndex + 1}`}/>
          
             <FontAwesomeIcon icon={faChevronRight} color="#00315b"
             onClick={showNextImage}
             size="2x"/>
            </div>
        </div>
    )

}
export default Image
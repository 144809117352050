import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import BASE_API_URL from '../../../utils/constants'

function CurrentContributions() {
    const auth = useAuth()

    const [message,setMessage] = useState('')
    const [variant,setVariant] = useState('danger')

    const [contributions, setContributions] = useState([])
    const [results, setResults] = useState([])

    const columns = [
		{
			name: 'Name',
			selector: row => row.name,
			sortable: true,
		},
		{
			name: 'Amount (Tsh)',
			cell: row => <span>
                {row.amount.toLocaleString()}
                {
                    row.type === "Per Person" &&
                    <span> / person</span>
                }
            </span>,
			sortable: true,
		}, 
        {
            name: 'Date Created',
            selector: row => row.date_created,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
		{
			name: 'Action',
			cell: row => <Link
				to={`/dashboard/contributions/${row.id}`}
				state={{ member: row }}
				className='btn btn-sm btn-primary'>
				More
                <i className='bi bi-three-dots-vertical ms-2'></i>
			</Link>,
			sortable: false,
		}
	]

    const handleSearch = (value) => {
        if (value.length < 1) {
            setResults(contributions)
            return
        }

        const searchResults = contributions.filter(contribution => {
            return contribution.name.toLowerCase().includes(value.toLowerCase())
        })
        setResults(searchResults)
    }

    const fetchContributions = async () => {
        try {
            const response = await fetch(`${BASE_API_URL}/contributions`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (response.ok) {
                setContributions(data.contributions)
                setResults(data.contributions)
            } else {
                setMessage(data.message)
                setVariant('danger')
            }
        } catch (error) {
            setMessage('An error occurred while fetching contributions.')
            setVariant('danger')
        }
    }

    useEffect(() => {
        fetchContributions()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Current Contributions</p>
                <Link to={"new"} className='btn btn-sm btn-primary fw-bold'>
                    Create <i className='bi bi-cash-coin ms-2'></i>
                </Link>
            </div>
            <div className="body shadow rounded p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1"
                    placeholder="Search contributions..."
                    onChange={e => handleSearch(e.target.value)}
                />
                <button className="btn btn-sm btn-primary px-3">
                    Search
                </button>
            </div>
            {
                message &&
                <div className={`alert alert-${variant}`} role="alert">
                    {message}
                </div>
            }
            <div>
                <DataTable
                    columns={columns}
                    data={results}
                    highlightOnHover
                    pagination
                    paginationPerPage={20}
                    noDataComponent={"No contributions yet."}
                />
            </div>
        </div>
    )
}

export default CurrentContributions

import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'

function NewContribution() {
    const auth = useAuth()

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("danger")
    const [isLoading, setIsLoading] = useState(false)

    const [name, setName] = useState("")
    const [amount, setAmount] = useState("")
    const [description, setDescription] = useState("")
    const [type, setType] = useState("Total")

    const handleSave = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage("")

        const data = {
            name,
            amount,
            description,
            type
        }

        try {
            const response = await fetch(`${BASE_API_URL}/contributions`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify(data)
            })

            const result = await response.json()
            if (response.ok) {
                setMessage(result.message)
                setVariant("success")
            }
            else {
                setMessage(result.message)
                setVariant("danger")
            }
        }
        catch (error) {
            setMessage("Something went wrong. Try again.")
            setVariant("danger")
        }
        setIsLoading(false)
    }

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Create Contribution</p>
            <div className='shadow rounded my-3 p-2'>
                {
                    message && <div className={`alert alert-${variant} p-2`}>{message}</div>
                }
                <form onSubmit={handleSave}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Contribution Name</label>
                        <input
                            type='text'
                            className='form-control'
                            name='name'
                            id='name'
                            placeholder='Contribution Name'
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="amount" className="form-label">Amount (Tsh)</label>
                        <input
                            type='text'
                            className='form-control'
                            name='amount'
                            id='amount'
                            placeholder='10000.00'
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="type" className="form-label">Amount is per person?</label>
                        <span className='mx-1'>
                            {type === "Per Person" ? "Yes" : "No "}
                        </span>
                        <input
                            type='checkbox'
                            class="form-check-input ms-2"
                            name='type'
                            id='type'
                            checked={type === "Per Person"}
                            onChange={e => (
                                e.target.checked ? setType("Per Person") : setType("Total")
                            )}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="amount" className="form-label">Description</label>
                        <textarea
                            className="form-control"
                            name="description"
                            id="description"
                            rows="3"
                            placeholder='A short description of the contribution'
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            required>
                        </textarea>
                    </div>
                    <div className="mb-3 text-end">
                        <button type="submit" className="btn btn-primary px-4">
                            {
                                isLoading ? 'Creating...' : 'Create'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewContribution

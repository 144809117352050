import React,{useRef,useEffect} from "react";

const ScrollFadeIn=({children,direction,fromBottom})=>{
    const ref=useRef(null);

    useEffect(()=>{
        const observer=new IntersectionObserver(
            ([entry])=>{
                if(entry.isIntersecting){
                    //then element in view then this is styling 
                    ref.current.style.opacity=1;
                    ref.current.style.transform='translate(0)';
                }
            },
            {
                threshold:0.75
            }
        );

        //observing the target element
        if(ref.current){
            observer.observe(ref.current);
        }

        //to clean up the observer when the component is unmounted

        return () =>{
            if(ref.current){
                observer.unobserve(ref.current);
            }
        };
    },[]);

    // const translateDirection = index % 2 === 0 ? 'right' : 'left';
    const initialTranslate = direction === 'left' ? 'translateX(-50px)' : 'translateX(50px)';
    const initialTranslateY = fromBottom ? 'translateY(-50px)' : 'translateY(50px)';
    return(
        <div
        ref={ref}
        style={{
            opacity:0,
            transition:'opacity 2s ease-out,transform 2s ease-out',
            transform:initialTranslateY,
        }}
        >{children}</div>
    )
}
export default ScrollFadeIn;
import react from "react"
import './Leader.css'
// import Chair  from './../../Images/20230429_162817-removebg-preview.jpg'
import Vice  from './Leader/rachel.jpg'
import treasure  from './Leader/costerr.jpg'
import secretary  from './Leader/ngusa.jpg'
import inspector from './Leader/kagashe.jpg'
import deputy from './Leader/theresia.jpg'
import secCalvary from './Leader/loveness.jpg'
import religion from './Leader/joshua.jpg'
import projector from './Leader/mtende.jpg'
import viceprojector from './Leader/majariwa.jpg'
import possibility from './Leader/vestus.jpg'
import chair from './Leader/chesc.jpg'
import deputyTre from './Leader/ester.jpg'
import coordinator from './Leader/mary.jpg'
import communication from './Leader/Enock.jpg'
import Depcommunication from './Leader/amson.jpg'
// import Secretary  from './../../Images/IMG-20240225-WA0032.jpg'
// import treasure  from './../../Images/costa.jpg'

const Leader=()=>{
    const LeadersData=[
        {
            id:"1",
            image:chair,
            position:"Chairperson at TucasaCive",
            name:'Chesco A Mwangoka',
            course:"Bach. in Computer Engineering",
            YearOfstudy:"Third Year"
        }, {
            id:"2",
            image:Vice,
            position:"Deputy Chairperson at TucasaCive",
            name:"Rachel Raphael",
            course:"Bach. in Multimedia Technology and Animation",
            YearOfstudy:"Third Year"
        }, {
            id:"3",
            image:secretary,
            position:" Executive Secretary at Tucasa Cive ",
            name:"Samwel Ngusa",
            course:"Bachelor In Software Engineering",
            YearOfstudy:"Second Year"

        }, {
            id:"4",
            image:deputy,
            name:"Theresia Meela",
            position:"Deputy Secretary at Tucasa Cive",
            course:"Bachelor In Digital Content & BroadCasting Engineering",
            YearOfstudy:"Third Year"
        },{
          id:"5",
          image:treasure,
          position:"Treasurer at Tucasa Cive",
          name:"Costantine Boniphace",
          course:"Bachelor in Computer Engineering",
          YearOfstudy:"Third Year"
      },{
        id:"6",
        image:deputyTre,
        position:"Deputy Treasurer at Tucasa Cive",
        name:"Esther Manenge",
        course:"Bachelor in Business Information System",
        YearOfstudy:"Second Year"
    },    {
        id:"7",
        image:"",
        position:"Spiritual Leader ",
        name:"Grace Samwel",
        course:"Bachelor In Cyber Security In Digital Forensic Engineering",
        YearOfstudy:"Second Year"
    },
    {
        id:"8",
        image:"",
        position:"Deputy Spiritual Leader ",
        name:"Tumaini Mataaluma",
        course:"Bach. in Multimedia Technology and Animation",
        YearOfstudy:"Second Year"
    },{
        id:"9",
        image:inspector,
        position:"Internal Orditor",
        name:"Fidelis Kagashe",
        course:"Bachelor In Computer Engineering",
        YearOfstudy:"Third Year"
    },{
        id:"10",
        image:secCalvary,
        position:"Secretary at Calvary Choir",
        name:"Loveness Zabron",
        course:"Bachelor In Business Information Sysyem",
        YearOfstudy:"Second Year"
    },{
        id:"11",
        image:possibility,
        position:"Posibility Ministries",
        name:"Vestus ",
        course:"Bach. in Multimedia Technology and Animation",
        YearOfstudy:"Third Year"
    },{
        id:"12",
        image:"",
        position:"Medical Missionary",
        name:"Amos Manda",
        course:"Bach. in Health Information System",
        YearOfstudy:"Second Year"
    }, {
        id:"13",
        image:projector,
        position:"  Project Management Director",
        name:"Evance Mtende",
        course:"Bachelor In Digital Content & BroadCasting Engineering",
        YearOfstudy:"Third Year"
    },
    {
        id:"14",
        image:viceprojector,
        position:" Deputy Project Management Director",
        name:"Majariwa Pius ",
        course:"Bachelor In Information System",
        YearOfstudy:"Third Year"
    },{
        id:"15",
        image:religion,
        position:"Religion Freedom",
        name:"Joshua Masawe ",
        course:"Bachelor In Digital Content & BroadCasting Engineering",
        YearOfstudy:"Third Year"
    },
    {
        id:"16",
        image:"",
        position:"Programme Coordinator",
        name:"James Nyakwala",
        course:"Bachelor In Telecommunication Engineering",
        YearOfstudy:"Second Year"
    },{
        id:"17",
        image:coordinator,
        position:"Programme Coordinator",
        name:"Mary Mwema",
        course:"Bachelor In Telecommunication Engineering",
        YearOfstudy:"Second Year"
    },{
        id:"18",
        image:Depcommunication,
        position:"Communication Leader",
        name:"Amson Samson",
        course:"Diploma In Cyber Security and Digital Forensics",
        YearOfstudy:"Second Year"
    },{
        id:"19",
        image:communication,
        position:"Deputy Communication Leader",
        name:"Enock Enock",
        course:"Bachelor In Computer Science",
        YearOfstudy:"Second Year"
    },{
        id:"20",
        image:"",
        position:"Education Leaderr",
        name:"Shija Ndipo",
        course:"Bachelor In Information System",
        YearOfstudy:"Third Year"
    },
    ]
return(
    <div className="Leader">
    <div className="Leader-container">
        <h2>2023-2024</h2>
        <div className="leader-alaignment">


                              {
                                  LeadersData.map((leader)=>{
                                        return(
                                             <div className="single-leader-container" key={leader.id}>
                                                   <div className="single-Image-contanier">
                                                          {
                                                            <img  src={leader.image} alt="images of leaders"  />
                                                          }
                                                        <div className="single-leader-content">
                                                              <div className="single-names">Name: {leader.name}</div>
                                                               <div className="">position: {leader.position}</div>
                                                               <div className="">course: {leader.course}</div>
                                                               <div className="">Year: {leader.YearOfstudy}</div>
                                                        </div>
                                                    </div>
                                                   
                                              
                                              </div>
                                               )
                                              })
                               }
        
      
        </div>

    </div>
    </div>
)

}
export default Leader
import react from "react-dom"
import { Link, useNavigate } from "react-router-dom"
// import { MdEmail } from "react-icons/md";
// import { AiFillEye } from "react-icons/ai";
import "./Login.css"
import { useEffect, useState } from "react"
import BASE_API_URL from "../../utils/constants"
import { useAuth } from "../../utils/AuthContext"

// import Register from "./Register"

const Login = ({msg}) => {
    const navigate = useNavigate()
    const auth = useAuth()

    const [message, setMessage] = useState(msg || "")
    const [variant, setVariant] = useState("danger")

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()
        setMessage("")
        
        try {
            const res = await fetch(`${BASE_API_URL}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username,
                    password
                })
            })

            const data = await res.json()
            if (!res.ok) {
                setMessage(data.message)
                setVariant("danger")
            }
            else{
                auth.login(data.token)
                navigate("/dashboard/index")
            }
        } catch (err) {
            setMessage("Something went wrong, Please try again.")
            setVariant("danger")
            console.log(err);
        }
    }

    useEffect(() => {
        if (auth.isLoggedIn()) {
          navigate("/dashboard/index")
          return
        }
      }, [])

    return (
        <div className="login">
            <div className="form-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <h2>TCMS - LOGIN</h2>
                    <div className="message">
                        { message && <div className={`alert alert-${variant}`}>{message}</div>}
                    </div>
                    <label htmlFor="email" className="email-label">
                        {/* <MdEmail size="1.5rem"/> */}
                        <input 
                            type="text" 
                            placeholder="Email" 
                            id="email" 
                            required 
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </label>
                    <label htmlFor="password" className="password-label">
                        <input 
                            type="password" 
                            placeholder="password" 
                            id="password" 
                            required 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {/* <AiFillEye size="1.5rem"/> */}
                    </label>
                    {/* <div className="fp">Forgot password? </div> */}
                    <button type="submit" to="/" className="loginButton">
                        LOG-IN
                    </button>
                    <div>
                        Do not Have a profile? 
                        <span>
                            <Link to="/Register">Sign-up</Link>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Login
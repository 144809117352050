import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'
import ChairpersonsOffice from '../ChairpersonsOffice'

function Archives() {
    const auth = useAuth()

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")

    const [archives, setArchives] = useState([])

    const fetchArchives = async () => {
        setMessage("")

        try {
            const res = await fetch(`${BASE_API_URL}/archives`, {
                headers: {
                    "x-access-token": auth.token
                }
            })
            const data = await res.json()

            if (!res.ok) {
                setMessage("Failed to fetch data")
                setVariant("danger")
            }
            else {
                setArchives(data.archives)
                setMessage("")
            }
        } catch (error) {
            console.log("Something went wrong. Please try again")
        }
    }

    useEffect(() => {
        fetchArchives()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Archives</p>
                <ChairpersonsOffice>
                    <Link to={"upload"} className='btn btn-sm btn-primary fw-bold'>
                        Upload <i className='bi bi-upload ms-1'></i>
                    </Link>
                </ChairpersonsOffice>
            </div>
            <div className="body shadow rounded p-2 mb-3">
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
                {
                    archives.length > 0 &&
                    <div className="row mx-0 mb-3">
                        {
                            archives.map((archive, index) => (
                                <Link
                                    to={`${archive.id}`}
                                    key={index} className="col-12 col-lg-auto mx-2 rounded border p-2 mb-2 link text-dark"
                                >
                                    Title: {archive.title}<br />
                                    Uploaded by: {
                                        `${archive.uploaded_by.firstname} ${archive.uploaded_by.lastname}`
                                    }<br />
                                    {/* format date in YYYY-MM-dd */}
                                    Date: {new Date(archive.date_created).toISOString().split('T')[0]}<br />
                                </Link>
                            ))
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Archives

import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'
import { useLocation } from 'react-router'

function CurrentLeaders({ type }) {
    const auth = useAuth()
    const location = useLocation()

    const [message, setMessage] = useState()
    const [variant, setVariant] = useState()
    const [isLoading, setIsLoading] = useState()

    const [leaders, setLeaders] = useState([])

    const fetchLeaders = async () => {
        setMessage("")
        setIsLoading(true)

        try {
            let url = `${BASE_API_URL}/leadership/current`
            if (type !== "current") {
                url = `${BASE_API_URL}/leadership/history`
            }

            const resp = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await resp.json()

            if (!resp.ok) {
                setMessage(data.message)
                setVariant("danger")
            }
            else {
                if (type === "current") {
                    setLeaders(data.leaders)
                }
                else {
                    setLeaders(data.leadership)
                }
                setMessage("")
            }
        }
        catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchLeaders()
    }, [location])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>
                {
                    type === "current" ? "Current Leaders" : "Leadership History"
                }
            </p>
            <div className="body shadow rounded p-2 mb-3">
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <div className="row mx-0">
                    {
                        (leaders && leaders.length > 0) ?
                            <>
                                {
                                    leaders.map((l, i) => (
                                        <div className="col-12 col-lg-3 m-2 p-2 border rounded text-start" key={i}>
                                            <div className="text-center">
                                                <img src={`${BASE_API_URL}${l.user.profile}`} alt={``} className="img-fluid rounded-circle m-1" style={{ objectFit: "cover", width: "200px", height: "200px" }} loading='lazy'/>
                                            </div>
                                            <span>{l.user.firstname} {l.user.lastname}</span><br />
                                            <span>{l.role}</span><br />
                                            {
                                                type !== "current" &&
                                                <span>
                                                    {l.start_date} - {l.end_date}
                                                </span>
                                            }
                                        </div>
                                    ))
                                }
                            </>
                            :
                            <div className="text-center">
                                No Leadership record.
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CurrentLeaders

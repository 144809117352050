import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'

function NewSpiritualRecords() {
    const auth = useAuth()

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [spirituals, setSpirituals] = useState([])

    const fetchSpirituals = async () => {
        setMessage("")

        try {
            const res = await fetch(`${BASE_API_URL}/spirituals`, {
                headers: {
                    "x-access-token": auth.token
                }
            })
            const data = await res.json()

            if (!res.ok) {
                setMessage("Failed to fetch data")
                setVariant("danger")
            }
            else {
                setSpirituals(data.spirituals)
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage("")

        const data = {
            spirituals: spirituals
        }

        try {
            const res = await fetch(`${BASE_API_URL}/spirituals`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": auth.token
                },
                body: JSON.stringify(data)
            })

            const response = await res.json()
            if (!res.ok) {
                setMessage("Failed to save spiritual records")
                setVariant("danger")
            }
            else {
                setMessage("Spiritual records saved successfully")
                setVariant("success")
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }

        setIsLoading(false)
    }

    useEffect(() => {
        fetchSpirituals()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>New Spiritual Records</p>
            <div className='shadow rounded p-2'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        {
                            spirituals.map(spiritual => (
                                <div className='col-lg-4 mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor='firstName'>{spiritual["title"]}</label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            id='firstName'
                                            required
                                            value={spiritual["count"]}
                                            onChange={(e) => {
                                                setSpirituals(spirituals.map(sp => {
                                                    if (spiritual["id"] === sp["id"]) {
                                                        return {
                                                            ...sp,
                                                            count: e.target.value
                                                        }
                                                    }
                                                    return sp
                                                }))
                                            }}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="mb-3 text-end">
                        <button type='submit' className='btn btn-primary px-4'>
                            {
                                isLoading ? "Saving..." : "Save"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewSpiritualRecords

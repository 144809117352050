import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'
import { useParams } from 'react-router'
import ChairpersonsOffice from '../ChairpersonsOffice'

function ViewMember() {
	const auth = useAuth()
	const user = auth.user
	const params = useParams()
	const memberId = params.memberId

	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('success')

	const [firstName, setFirstName] = useState('')
	const [middleName, setMiddleName] = useState('')
	const [lastName, setLastName] = useState('')
	const [gender, setGender] = useState('')
	const [baptism, setBaptism] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [residence, setResidence] = useState('')
	const [yos, setYos] = useState('')
	const [dob, setDob] = useState('')
	const [program, setProgram] = useState('')
	const [role, setRole] = useState('')
	const [status, setStatus] = useState('')

	const fetchMember = async () => {
		try {
			const resp = await fetch(`${BASE_API_URL}/member/${memberId}`, {
				headers: {
					'x-access-token': auth.token
				}
			})
			const data = await resp.json()

			if (!resp.ok) {
				setMessage(data.message)
				setVariant("danger")
			}

			setFirstName(data.member.firstname)
			setMiddleName(data.member.middlename)
			setLastName(data.member.lastname)
			setGender(data.member.gender)
			setBaptism(data.member.baptism_status)
			setPhoneNumber(data.member.phone)
			setEmail(data.member.username)
			setResidence(data.member.residence)
			setYos(data.member.yos)
			// set date to this format: yyyy-MM-dd
			setDob(new Date(data.member.dob).toISOString().split('T')[0])
			setProgram(data.member.program)
			setRole(data.member.role)
			setStatus(data.member.status)
		}
		catch (error) {
			setMessage("Something went wrong. Please try again")
			setVariant("danger")
		}
	}

	const toogleStatus = async () => {
		try {
			const resp = await fetch(`${BASE_API_URL}/member/${memberId}/status`, {
				method: "PUT",
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token
				}
			})
			const data = await resp.json()

			if (!resp.ok) {
				setMessage(data.message)
				setVariant("danger")
			}
			else {
				setMessage(data.message)
				setVariant("success")
				setStatus(data.status)
			}
		}
		catch (error) {
			setMessage("Something went wrong. Please try again")
			setVariant("danger")
		}

	}

	useEffect(() => {
		fetchMember()
	}, [])

	return (
		<div className='pt-2'>
			<div className="d-flex align-items-center justify-content-between mb-3">
				<p className='h3 fw-bold text-muted mb-3'>Member Details</p>
				{
					
					<ChairpersonsOffice>
						{
							status === 'Inactive' ?
								<div>
									<button className="btn btn-sm btn-primary fw-bold" onClick={toogleStatus}>
										Activate <i className='bi bi-unlock ms-1'></i>
									</button>
								</div> :
								<div>
									<button className="btn btn-sm btn-danger fw-bold" onClick={toogleStatus}>
										Deactivate <i className='bi bi-lock ms-1'></i>
									</button>
								</div>
						}
					</ChairpersonsOffice>
				}
			</div>
			<div className='shadow rounded p-2'>
				{
					message &&
					<div className={`alert alert-${variant}`} role="alert">
						{message}
					</div>
				}
				<form>
					<p className='h4 mb-3 text-muted'>Basic Info</p>
					<div className='row'>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='firstName'>First Name</label>
								<input
									type='text'
									className='form-control'
									id='firstName'
									required
									disabled
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='middleName'>Middle Name</label>
								<input
									type='text'
									className='form-control'
									id='middleName'
									required
									disabled
									value={middleName}
									onChange={(e) => setMiddleName(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='lastName'>Last Name</label>
								<input
									type='text'
									className='form-control'
									id='lastName'
									required
									disabled
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='gender'>Gender</label>
								<select
									className='form-select'
									id='gender'
									required
									disabled
									value={gender}
									onChange={(e) => setGender(e.target.value)}
								>
									<option value="">Select Gender</option>
									<option value="Female">Female</option>
									<option value="Male">Male</option>
								</select>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='dob'>Date of Birth</label>
								<input
									type='date'
									className='form-control'
									id='dob'
									required
									disabled
									value={dob}
									onChange={(e) => setDob(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='baptism'>Baptisim</label>
								<select
									className='form-select'
									id='baptism'
									required
									disabled
									value={baptism}
									onChange={(e) => setBaptism(e.target.value)}
								>
									<option value="">Select Baptism Status</option>
									<option value="Baptised">Baptised</option>
									<option value="Not Baptised">Not Baptised</option>
								</select>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<p className='h4 mb-3 text-muted'>Contact Info</p>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='phone'>Phone Number (255...)</label>
								<input
									type='text'
									className='form-control'
									id='phone'
									required
									disabled
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='email'>Email Address (Username)</label>
								<input
									type='email'
									className='form-control'
									id='email'
									required
									disabled
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='residence'>Residence</label>
								<input
									type='text'
									className='form-control'
									id='residence'
									required
									disabled
									value={residence}
									onChange={(e) => setResidence(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<p className='h4 mb-3 text-muted'>Academic Info</p>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='yos'>Year of Study</label>
								<select
									className='form-select'
									id='yos'
									required
									disabled
									value={yos}
									onChange={(e) => setYos(e.target.value)}
								>
									<option value="">Select Year of Study</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
								</select>
							</div>
						</div>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='program'>Program</label>
								<input
									type='text'
									className='form-control'
									id='program'
									required
									disabled
									value={program}
									onChange={(e) => setProgram(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<p className='h4 mb-3 text-muted'>Leadership Info</p>
						<div className='col-lg-4 mb-3'>
							<div className='form-group'>
								<label htmlFor='role'>Role</label>
								<input
									type='text'
									className='form-control'
									id='role'
									required
									disabled
									value={role}
									onChange={(e) => setRole(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ViewMember

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'
import ChairpersonsOffice from '../ChairpersonsOffice'

function Announcements() {
    const auth = useAuth()

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")

    const [announcements, setAnnouncements] = useState([])

    const fetchAnnouncements = async () => {
        setMessage("")

        try {
            const res = await fetch(`${BASE_API_URL}/announcements`, {
                headers: {
                    "x-access-token": auth.token
                }
            })
            const data = await res.json()

            if (!res.ok) {
                setMessage("Failed to fetch data")
                setVariant("danger")
            }
            else {
                setMessage("")
                setAnnouncements(data.announcements)
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("warning")
        }
    }

    useEffect(() => {
        fetchAnnouncements()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Announcements</p>
                <ChairpersonsOffice>
                    <Link to={"new"} className='btn btn-sm btn-primary fw-bold'>
                        Post <i className='bi bi-file-earmark-arrow-up-fill ms-1'></i>
                    </Link>
                </ChairpersonsOffice>
            </div>
            <div className="body mb-3">
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
                {
                    announcements.length > 0 ?
                        <div className="row mx-0 mb-3">
                            {
                                announcements.map((announcement) => (
                                    <Link to={`${announcement.id}`} key={announcement.id} className='col-12 col-lg-4 mb-3 text-decoration-none'>
                                        <div className="card shadow rounded">
                                            {
                                                announcement.image ?
                                                    <img
                                                        src={`${BASE_API_URL}/${announcement.image}`}
                                                        className="card-img-top"
                                                        alt={announcement.title}
                                                        style={{ height: '200px' }}
                                                        loading='lazy'
                                                    />
                                                    :
                                                    <div
                                                        style={{ height: '200px', backgroundColor: 'lightgray' }}
                                                    >
                                                    </div>
                                            }
                                            <div className="card-body d-flex align-items-center justify-content-between">
                                                <div>
                                                    <span>{announcement.title}</span>
                                                    <br />
                                                    <span className="text-muted">{new Date(announcement.created_at).toDateString()}</span>
                                                    <br />
                                                    <span className='text-muted'>
                                                        {
                                                            announcement.visibility === "Public" ?
                                                                <i className="bi bi-eye-fill me-2"></i>
                                                                :
                                                                <i className="bi bi-eye-slash-fill me-2"></i>
                                                        }
                                                        {announcement.visibility}
                                                    </span>
                                                    <br />
                                                    <span className='text-muted'>
                                                        {
                                                            announcement.status === "Active" ?
                                                                <i className="bi bi-unlock-fill me-2"></i>
                                                                :
                                                                <i className="bi bi-lock-fill me-2"></i>
                                                        }
                                                        {announcement.status}
                                                    </span>
                                                </div>
                                                <div>
                                                    <i className="bi bi-chevron-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                        :
                        <div className='text-center'>
                            <p className='text-muted h5'>No announcements yet</p>
                        </div>
                }
            </div>
        </div>
    )
}

export default Announcements

import React, { useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'

function UploadArchive() {
    const auth = useAuth()

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [document, setDocument] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()
        setMessage("")
        setIsLoading(true)

        const formData = new FormData()
        formData.append('title', title)
        formData.append('description', description)
        formData.append('document', document)

        try {
            const res = await fetch(`${BASE_API_URL}/archives`, {
                method: "POST",
                headers: {
                    "x-access-token": auth.token
                },
                body: formData
            })

            const response = await res.json()
            if (!res.ok) {
                setMessage(response.message)
                setVariant("danger")
            }
            else {
                setMessage(response.message)
                setVariant("success")
            }
        } catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
        setIsLoading(false)
    }

    return (
        <>
            <div className='pt-2'>
                <p className='h3 fw-bold text-muted'>Upload Document</p>
            </div>
            <div className="shadow rounded p-2 mb-3">
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }
                <form action="" encType='multipart/form-data' onSubmit={handleSubmit}>
                    <div className='form-group mb-3'>
                        <label htmlFor='title'>Document Title</label>
                        <input
                            type='text'
                            className='form-control'
                            id='title'
                            required
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="description" className="form-label">Document Description</label>
                        <textarea
                            className="form-control"
                            name="description"
                            id="description"
                            rows="3"
                            required
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}>
                        </textarea>
                    </div>
                    <div className="form-group mb-3">
                        <label htmlFor="document" className="form-label">Document</label>
                        <input
                            type="file"
                            className="form-control"
                            id="document"
                            name="document"
                            accept=".pdf, .doc, .docx, .xls, .xlsx, .zip"
                            required
                            onChange={(e) => setDocument(e.target.files[0])}
                        />
                        <small className="form-text text-muted">Only .pdf, .doc, .docx, .xls, .xlsx, .zip files are allowed</small>
                        <br />
                        <small className="form-text text-muted">Max file size: 25MB</small>
                    </div>
                    <div className="text-end mb-3">
                        <button type="submit" className="btn btn-primary px-4">
                            {
                                isLoading ? "Uploading..." : "Upload"
                            }
                            <i className="bi bi-upload ms-2" />
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UploadArchive

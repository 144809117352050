import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import BASE_API_URL from '../../../utils/constants'
import { BarChart, LineChart } from '@mui/x-charts'

function ViewSpiritualRecords() {
	const auth = useAuth()

	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('warning')

	const today = new Date()
	const [startDate, setStartDate] = useState(today.toISOString().split('T')[0])
	const [endDate, setEndDate] = useState(today.toISOString().split('T')[0])
	const [records, setRecords] = useState([])
	const [summary, setSummary] = useState({})

	const handleFilter = async (e) => {
		if (e) e.preventDefault()
		setMessage("")

		try {
			const res = await fetch(`${BASE_API_URL}/spirituals/${startDate}/${endDate}`, {
				headers: {
					"x-access-token": auth.token
				}
			})
			const data = await res.json()
			console.log(data);

			if (!res.ok) {
				setMessage("Failed to fetch data")
				setVariant("danger")
			}
			else {
				setRecords(data.records)
				setSummary(data.summary)
			}
		}
		catch (error) {
			setMessage("Something went wrong. Please try again")
			setVariant("danger")
		}
	}

	useEffect(() => {
		handleFilter(null)
	}, [])

	return (
		<div className='pt-2'>
			<p className='h3 fw-bold text-muted mb-3'>Spiritual Records</p>
			<form
				className='shadow rounded p-2 mb-3 d-flex align-items-center justify-content-center'
				onSubmit={handleFilter}>
				<input
					type="date"
					className='form-control w-auto'
					name="start_date"
					id="start_date"
					value={startDate}
					onChange={(e) => { setStartDate(e.target.value) }}
				/>
				<span className='fw-bold'>&nbsp;-&nbsp;</span>
				<input
					type="date"
					className='form-control w-auto me-1'
					name="end_date"
					id="end_date"
					min={startDate}
					value={endDate}
					onChange={(e) => { setEndDate(e.target.value) }}
				/>
				<button className='btn btn-primary px-3' type='submit'>
					<i className='bi bi-search'></i>
				</button>
			</form>

			<div className='shadow rounded p-2 mb-3'>
				{
					message &&
					<div className={`alert alert-${variant} p-2`} role="alert">
						{message}
					</div>
				}
				<div>
					<p className='h5 text-muted fw-bold'>Summary</p>
					{
						summary.length > 0 &&
						<div className='row mx-1'>
							{
								summary.map(s => (
									<div className='col-12 col-lg-2 rounded border m-1' key={s["id"]}>
										<h6 className='my-2'>{s["title"]}</h6>
										<h5 className='fw-bold'>{s["count"]}</h5>
									</div>
								))
							}
						</div>
					}
				</div>
			</div>

			<div className='shadow rounded p-2 mb-3'>
				<p className='h5 text-muted fw-bold'>Summary Plot</p>
				{
					summary.length > 0 &&
					<BarChart
						xAxis={[
							{
								id: 'barCategories',
								data: summary.map(s => s["title"]),
								scaleType: 'band',
							},
						]}
						series={[
							{
								data: summary.map(s => s["count"]),
							},
						]}
						colors={['#1764ff']}
						width={1000}
						height={400}
					/>
				}
			</div>

			<div className='shadow rounded p-2'>
				{
					Object.keys(records).map(r => (
						<div className='mb-3'>
							<p className='h5 text-muted fw-bold'>{r}</p>
							<div className='row mx-1'>
								{
									records[r].map(s => (
										<div className='col-12 col-lg-2 rounded border m-1' key={s["id"]}>
											<h6 className='my-2'>{s["title"]}</h6>
											<h5 className='fw-bold'>{s["count"]}</h5>
										</div>
									))
								}
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}

export default ViewSpiritualRecords

import React from 'react'
import { useAuth } from '../../utils/AuthContext'

function SpiritualsOffice({children}) {
    const auth = useAuth()
    const user = auth.user

    return (
        <>
            {
                user.role.includes("Spiritual") ?
                    <>{children}</> : <></>
            }
        </>
    )
}

export default SpiritualsOffice

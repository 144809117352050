import React from 'react'
import { useAuth } from '../../utils/AuthContext'

// hide children component if the current user isnt a member of chairperson's office
function ChairpersonsOffice({ children }) {
    const auth = useAuth()
    const user = auth.user

    return (
        <>
            {
                (user.role === "Chairperson" || user.role === "Secretary" || user.role === "Treasurer") ?
                    <>{children}</>:<></>
            }
        </>
    )
}

export default ChairpersonsOffice

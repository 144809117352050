import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import { useLocation, useParams } from 'react-router'
import BASE_API_URL from '../../../utils/constants'
import DataTable from 'react-data-table-component'

function ContributionMemberHistory() {
    const auth = useAuth()
    const params = useParams()
    const memberId = params.memberId
    const location = useLocation()
    const member = location.state.member
    const contribution = location.state.contribution

    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("danger")
    const [isLoading, setIsLoading] = useState(false)

    const [history, setHistory] = useState([])

    const columns = [
        {
            name: 'Date',
            selector: row => row.date_contributed,
            sortable: true,
        },
        {
            name: 'Amount (Tsh)',
            selector: row => `${row.amount.toLocaleString()}`,
            sortable: true,
        }
    ]

    const fetchHistory = async () => {
        setIsLoading(true)
        setMessage("")

        try {
            const response = await fetch(`${BASE_API_URL}/contributions/analysis/${memberId}/history/${contribution.id}`, {
                method: 'GET',
                headers: {
                    'x-access-token': auth.token
                }
            })

            const result = await response.json()
            if (response.ok) {
                setHistory(result.history)
                setMessage('')
            }
            else {
                setMessage(result.history)
                setVariant('danger')
                setHistory(null)
            }
        }
        catch (error) {
            setMessage("Something went wrong. Please try again")
            setVariant("danger")
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchHistory()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 mb-2 fw-bold text-muted'>Member Contribution History</p>
            <div className="mb-3 mx-2">
                <p>Member: <strong>{member.firstname} {member.lastname}</strong></p>
                <p>Contribution: <strong>{contribution.name}</strong></p>
            </div>
            <div className='shadow rounded mb-3 p-2'>
                {
                    message && <div className={`alert alert-${variant} p-2`}>{message}</div>
                }

                <div className="mb-3">
                    <DataTable
                        columns={columns}
                        data={history}
                        highlightOnHover
                        pagination
                        paginationPerPage={20}
                        noDataComponent={"No contributions made"}
                    />
                </div>
            </div>
        </div>
    )
}

export default ContributionMemberHistory

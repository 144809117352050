import React from "react";
import black from "./../Images/Black.jpg"
import revival from "./../Images/IMG-20231113-WA0025.jpg"
import speaker from "./../Images/IMG-20230630-WA0009.jpg"
import friday from "./../Images/255694240203_status_IMG-20231103-WA0002.jpg"

import prayer from "./../Images/IMG-20231114-WA0012.jpg"

export const Data=[
{     id:'jfghshjd',
       date:"23 dec 2023",
       image:black,
    tittle:"Hafla ya kuwakaribisha mwaka wa kwanza kitivon ",
    description:"welcome first year itakuwepo kwahiyo mchango wako mwanachama kwa hali na mali unahitajika tarehe husika itaelezwa hivi karibuni ila mchango ni shiringi 10,000/- kwa mwanakamati alafu 5000/- kwa mwanachama."
},{
    id:"gfeijg",
    date:"24 dec 2023",
    image:prayer,
    tittle:"yahusu juma la maombi week ya baada ya x-mas",

    description:"kutakuwa na huduma ya kufunga siku ya jumatano ya wiki amabapo pia kutakuwa na mchango wa pesa kwa ajili ya huduma ya chakula mara baada ya kufungua mfungo wetu mda wa usiku"
},{
  id:"ger23",
  date:"25 dec 2023",
  image:revival,
  tittle:"juma la uwamsho hapa TuCasa cive ",
  description:"habari ndugu mwanachama kutakuwa na wiki ya kujirejesha kwa muumba wetu ambapo itajumuisha huduma ya tofauti kidogo yaani maombi kutwa nzima"
},{     id:"123bjhbsdjfh",
date:"26 dec 2023",
image:speaker,
    tittle:"Changizo la spika siku zimekaribia sana",
    description:"changizo la spika ambapo ni shilingi 30,000/- kwa kila mwanachama na tunakumbusha kwamba siku zimeisha sana ko kila mtu na atumike kwa nafasi ake ili kazi ya Bwana iweze kusonga mbele mwisho itakuwa tarehe 31/12 mwaka huu "
},{
    id:"3221jk2dfnkj",
    date:"29 dec 2023",
    image:friday,
    tittle:"ijumaa ya ufunguzi wa sabato sii ya kukosa kabisa",
    description:"ijumaa ya week kutakuwa na programu nzuri kuwahi kutokea ambapo zitasimamiwa na vjana wa mwaka wa kwanza wote ambapo uimbaji ni sehemu ya ratiba kuu ya tukio husika pia swala la chsngizo la spika pia litajumuishwa pia bila kusahau finalisti wote mtaombwa kubaki baada ya ibada kuisha kwa mda mchache kidogo"
},{
  id:"325ndgverk",
  date:"31 dec 2023",
  image:black,
  tittle:"ukaribisho wa wanafunzi mwaka wa kwanza",
  description:"tafadhali mkuu wa mawasiliano tarehe 23 des fika ofisi ya wazee bila kukosa asubuhi saa tatu kamili mchana "
}
];
// MusicPlayer.js
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStepForward, faStepBackward, faSyncAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import './Music.css';
import websiteLogo from "./../../Images/sdalogo2.jpg"

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const formattedTime = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  return formattedTime;
};

const Music = ({ playlist }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLooping, setLooping] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;

    // Set up event listeners
    audio.addEventListener('timeupdate', updateTime);
    audio.addEventListener('ended', handleTrackEnd);

    // Clean up event listeners on component unmount
    return () => {
      audio.removeEventListener('timeupdate', updateTime);
      audio.removeEventListener('ended', handleTrackEnd);
    };
  }, [currentTrackIndex]);

  useEffect(() => {
    // Play the audio automatically when the track changes
    if (isPlaying) {
      audioRef.current.play();
    }
  }, [currentTrackIndex, isPlaying]);

  const playPauseToggle = () => {
    const audio = audioRef.current;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }

    setPlaying(!isPlaying);
  };

  const playNextTrack = () => {
    setCurrentTrackIndex((prevIndex) => (prevIndex === playlist.length - 1 ? 0 : prevIndex + 1));
    setPlaying(true); // Start playing automatically
  };

  const playPrevTrack = () => {
    setCurrentTrackIndex((prevIndex) => (prevIndex === 0 ? playlist.length - 1 : prevIndex - 1));
    setPlaying(true); // Start playing automatically
  };

  const updateTime = () => {
    const audio = audioRef.current;
    setCurrentTime(audio.currentTime);
    setDuration(audio.duration);
  };

  const handleTrackEnd = () => {
    // Auto play the next track when the current track ends
    if (!isLooping) {
      playNextTrack();
    }
  };

  const handleProgressBarChange = (e) => {
    const audio = audioRef.current;
    const newTime = e.target.value;
    audio.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const toggleLoop = () => {
    setLooping(!isLooping);
  };

  const downloadCurrentTrack = () => {
    const a = document.createElement('a');
    a.href = playlist[currentTrackIndex].url;
    a.download = `${playlist[currentTrackIndex].name}.mp3`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="music-player">
      <div className="music-section-1">
      <div className="Music-image">
            <img src={websiteLogo} alt="" />
        </div>
        <div className="progress-bar">
        <input type="range" value={currentTime} max={duration || 1} onChange={handleProgressBarChange} />
        <div className="time-info">
          <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
        </div>
      </div>
      </div>
      <div className="music-section-2">
      <div className="track-info">
        <p>{playlist[currentTrackIndex].name}</p>
      </div>
      <div className="controls">
        <FontAwesomeIcon icon={faStepBackward} onClick={playPrevTrack} size='2x'/>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} onClick={playPauseToggle} size='2x'/>
        <FontAwesomeIcon icon={faStepForward} onClick={playNextTrack} size='2x'/>
        <FontAwesomeIcon icon={faSyncAlt} className={isLooping ? 'loop-icon active' : 'loop-icon'} onClick={toggleLoop} size='1x' />
      
      </div>
     <div className="download"  onClick={downloadCurrentTrack}>
      <span>Download Track</span>
     <FontAwesomeIcon icon={faDownload} />
     <audio ref={audioRef} src={playlist[currentTrackIndex].url} loop={isLooping} controls={false} />
     </div>
      </div>
       
    


    </div>
  );
};

export default Music;


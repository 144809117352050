import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome,faBell, faAddressCard, faInfoCircle, faUserPlus, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import DarkModeToggle from "../../components/DarkMode/DarkMode";
import "./Navbar.css";
import logoTC  from './../../Images/20240112_121648.jpg'
import "./Navbar.css";
import { useAuth } from '../../utils/AuthContext';


function Navbar() {
    const auth = useAuth()
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false)
    }

    const handleNavLinkclick = () => {
        closeMenu()
    }
    return (
        <div className='Navbar'>


            <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
                <div className="navbar-container">
                <div className="navbar-logo">
            <span><img src={logoTC} alt="logo" style={{width:"25px", height:"25px",backgroundColor:"#c9c9c9",borderRadius:"50%",border:"1px solid #00315b"}} /> Tucasa Cive</span>
           
         </div>
                    <div className={`navbar-items ${isMenuOpen ? 'show' : ''}`}>

                        <Link to="/" className='navHome' onClick={handleNavLinkclick} >
                            Home
                        </Link>
                        <Link to="/about-us" className='nav-about' onClick={handleNavLinkclick} >
                            About Us
                        </Link>
                        <Link to="/leader" className='nav-about' onClick={handleNavLinkclick} >
                         Leaders
                        </Link>
                        <Link to="/news" className='nav-news' onClick={handleNavLinkclick}>
                            Announcements
                        </Link>
                        {
                            auth.isLoggedIn() ? (
                                <Link to="/dashboard/index" className='nav-profile' onClick={handleNavLinkclick}>
                                    Dashboard
                                </Link>
                            ) : (
                                <Link to="/login" className='nav-login' onClick={handleNavLinkclick}>
                                    Login
                                </Link>
                            )
                        }
                    </div>

                    <div className="menu-button" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
                    </div>
                </div>
            </nav>


        </div>
    )


} export default Navbar
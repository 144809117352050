import React from "react";
import { useState } from "react";
import { Link,useParams } from "react-router-dom";
import { Data } from "../data";
import { createBrowserHistory } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft,faClock } from '@fortawesome/free-solid-svg-icons';
import "./Single.css"

function Single(){

    const [isExpanded, setIsExpanded] = useState(false);
    const history = createBrowserHistory();
  
    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };
  
    const { newsId }=useParams();
    const news=Data.find((update)=> update.id === newsId);
    const { image,date,tittle,description}=news;

return (
    <section>
  
        <div className="anno"><h2>Announcement</h2></div>
      
       <h4 >{tittle}</h4>

     <div className="dateSingle">

                         <FontAwesomeIcon icon={faClock} size="1x" color="#00315B" />
                        <span>{date}</span>
                      
     </div>
       
  
   
        <div className="imageSingle">
<img src={image} alt="" />
        </div>
        <div onClick={toggleReadMore}>
        <p>{isExpanded ? description : `${description.slice(0, 100)}....More`}</p>
        </div>
        <div className="singleArrow">
     <Link to="/news" >   <FontAwesomeIcon icon={faArrowAltCircleLeft} size="3x" color="#00315b" /></Link>
     </div>

       
    </section>
)
}
export default Single